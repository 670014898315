<div class="w-100 bg-lavendar mt-80">
    <app-header></app-header>
</div>
<div class="py-3 px-3">
    <app-filter-card [pagetype]="pagetype" [queryParam]="params"></app-filter-card>
</div>
<div class="px-3 pb-3">
    <app-bread-crumb></app-bread-crumb>
</div>
<div class="px-3 pb-3">
    <div class="row">
        <div class="col-sm-2">
            <div class="single-left-pane">
                <app-side-menu></app-side-menu>
            </div>
        </div>
        <div class="col-sm-7">
            <div class="single-center-pane okr-rg">
                <h6>Edit OKRs</h6>
                <form [formGroup]="createForm">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item" *ngFor="let objective of createForm?.value; let i = index">
                            <h2 class="accordion-header" id="heading{{i}}">
                                <button class="accordion-button collapsed" *ngIf="!objective.edit" type="button"
                                    data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i"
                                    aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                                    <span><b>{{objective.title}}</b> <br>
                                        <span *ngIf="objective.end_date">End Date: {{objective.end_date | date:
                                            'mediumDate'}}</span><br>
                                        <span *ngIf="objective?.primary_owners?.length > 0"><b>Primary Owners :</b>
                                             <span *ngFor="let user of objective?.primary_owners;let memIndx=index">
                                                <span *ngIf="memIndx == 0">{{user?.first_name}} {{user?.last_name}}</span>
                                                <span *ngIf="memIndx != 0">, {{user?.first_name}} {{user?.last_name}}</span>
                                            </span></span><br>
                                        <span *ngIf="objective?.support_owners?.length > 0"><b>Secondary Owners :</b> 
                                            <span *ngFor="let user of objective?.support_owners;;let memIndx=index">
                                                <span *ngIf="memIndx == 0">{{user?.first_name}} {{user?.last_name}}</span>
                                                <span *ngIf="memIndx != 0">, {{user?.first_name}} {{user?.last_name}}</span>
                                            </span></span>    
                                    </span>
                                </button>
                                <span *ngIf="!objective.edit" class="edit-icon">
                                    <i class="fa fa-edit" style="font-size: 13px; margin-bottom: 5px"
                                        (click)="edit(i);$event.preventDefault();$event.stopPropagation();"></i>
                                    <img src="./assets/images/comment-delete.svg" (click)="removeObjective(i)"
                                        style="width:12px;">
                                </span>
                                <div class="p-3" *ngIf="objective.edit">
                                    <mat-form-field appearance="outline" class="w-75 pr-1">
                                        <mat-label>Objective title</mat-label>
                                        <input type="text" matInput fullWidth status="basic" [(ngModel)]="objective.edit_title"
                                            [ngModelOptions]="{standalone: true}">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-25 pl-1">
                                        <mat-label>End date</mat-label>
                                        <input matInput class="form-control" [matDatepicker]="picker" readonly
                                            class="fw-500" [(ngModel)]="objective.edit_end_date"
                                            [ngModelOptions]="{standalone: true}" fullWidth status="basic"
                                            (click)="picker.open()" name="end_date_name">
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker>
                                        </mat-datepicker>
                                    </mat-form-field>
                                    <br>
                                    <div class="d-flex">
                                        <div class="w-50 pl-1" >
                                            <span style="font-size: 14px;">Primary Owners</span>
                                            <span class="add-bn" (click)="openUserForObj(i,'primary')" title="Add Primary Owners">+</span>
                                            <br>
                                            <span style="font-size: 12px;" *ngFor="let members of objective?.edit_primary_owners; let memIndx = index">
                                                <span *ngIf="memIndx == 0">{{members.first_name}} {{members.last_name}}</span>
                                                <span *ngIf="memIndx != 0">, {{members.first_name}} {{members.last_name}}</span>
                                            </span>
                                            <!-- <span *ngIf="objective?.edit_primary_owners > 3" style="color: #4C78CE; font-style: italic; cursor: pointer;font-size:12px" (click)="openUser(i)">, + {{objective?.edit_primary_owners.length - 3}} more</span> -->
                                        </div>

                                        <div class="w-50 pl-1" >
                                            <span style="font-size: 14px;">Secondary Owners</span>
                                            <span class="add-bn" (click)="openUserForObj(i,'secondary')" title="Add Secondary Owners">+</span>
                                            <br>
                                            <span style="font-size: 12px;" *ngFor="let members of objective?.edit_secondary_owners; let memIndx = index">
                                                <span *ngIf="memIndx == 0">{{members.first_name}} {{members.last_name}}</span>
                                                <span *ngIf="memIndx != 0">, {{members.first_name}} {{members.last_name}}</span>
                                            </span>
                                            <!-- <span *ngIf="objective?.edit_primary_owners > 3" style="color: #4C78CE; font-style: italic; cursor: pointer;font-size:12px" (click)="openUser(i)">, + {{objective?.edit_primary_owners.length - 3}} more</span> -->
                                        </div>
                                    </div>
                                    
                                    <div class="row mt-2">
                                        <div class="col-md-3 ml-auto"
                                            style="display: flex; justify-content: space-between;">
                                            <button (click)="cancel(i)" mat-flat-button class="border bg-light">Cancel
                                            </button>
                                            <button (click)="update(i)" mat-flat-button class="" color="primary">Ok
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </h2>
                            <div id="collapse{{i}}" class="accordion-collapse collapse"
                                [attr.aria-labelledby]="'heading'+i" [attr.data-bs-parent]="'#accordionExample'">
                                <div class="accordion-body">
                                    <div class="accordion" id="accordion{{i}}">
                                        <div class="accordion-item"
                                            *ngFor="let kr of objective?.key_results; let j = index">
                                            <h2 class="accordion-header " id="heading{{i}}{{j}}">
                                                <button class="accordion-button collapsed" *ngIf="!kr.edit"
                                                    type="button" data-bs-toggle="collapse"
                                                    [attr.data-bs-target]="'#collapse' + i + j" aria-expanded="false"
                                                    [attr.aria-controls]="'collapse' + i + j">
                                                    <span><b>{{kr.title}}</b> <br>
                                                        <span *ngIf="kr.end_date">End Date: {{kr.end_date | date:
                                                            'mediumDate'}}</span><br>
                                                        <span *ngIf="kr?.primary_owners?.length > 0"><b>Primary Owners :</b> 
                                                            <span *ngFor="let user of kr?.primary_owners;let memIndx=index">
                                                                <span *ngIf="memIndx == 0">{{user?.first_name}} {{user?.last_name}}</span>
                                                                <span *ngIf="memIndx != 0">, {{user?.first_name}} {{user?.last_name}}</span>
                                                            </span>
                                                        </span><br>
                                                        <span *ngIf="kr?.support_owners?.length > 0"><b>Secondary Owners :</b> 
                                                            <span *ngFor="let user of kr?.support_owners;let memIndx=index">
                                                                <span *ngIf="memIndx == 0">{{user?.first_name}} {{user?.last_name}}</span>
                                                                <span *ngIf="memIndx != 0">, {{user?.first_name}} {{user?.last_name}}</span>
                                                            </span></span>
                                                    </span>
                                                </button>
                                                <span *ngIf="!kr.edit" class="edit-icon">
                                                    <i class="fa fa-edit" style="font-size: 13px; margin-bottom: 5px"
                                                        (click)="edit(i, j);$event.preventDefault();$event.stopPropagation();"></i>
                                                    <img src="./assets/images/comment-delete.svg"
                                                        (click)="removeKeyResult(i, j)" style="width:12px;">
                                                </span>
                                                <div class="p-3" *ngIf="kr.edit">
                                                    <mat-form-field appearance="outline" class="w-75 p2-1">
                                                        <mat-label>Keyresult title</mat-label>
                                                        <input type="text" matInput fullWidth status="basic" [(ngModel)]="kr.edit_title"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </mat-form-field>
                                                    <mat-form-field appearance="outline" class="w-25 pl-1">
                                                        <mat-label>End date</mat-label>
                                                        <input matInput class="form-control" [matDatepicker]="picker"
                                                            readonly class="fw-500"
                                                            [(ngModel)]="kr.edit_end_date"
                                                            [ngModelOptions]="{standalone: true}" fullWidth
                                                            status="basic" (click)="picker.open()" name="end_date_name">
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker>
                                                        </mat-datepicker>
                                                    </mat-form-field>
                                                    <br>
                                                    <div class="d-flex">
                                                        <div class="w-50 pl-1" >
                                                            <span style="font-size: 14px;">Primary Owners</span>
                                                            <span class="add-bn" (click)="openUserForKR(i,j,'primary')" title="Add Primary Owners">+</span>
                                                            <br>
                                                            <span style="font-size: 12px;" *ngFor="let members of kr?.edit_primary_owners; let memIndx = index">
                                                                <span *ngIf="memIndx == 0">{{members.first_name}} {{members.last_name}}</span>
                                                                <span *ngIf="memIndx != 0">, {{members.first_name}} {{members.last_name}}</span>
                                                            </span>
                                                            <!-- <span *ngIf="objective?.edit_primary_owners > 3" style="color: #4C78CE; font-style: italic; cursor: pointer;font-size:12px" (click)="openUser(i)">, + {{objective?.edit_primary_owners.length - 3}} more</span> -->
                                                        </div>

                                                        <div class="w-50 pl-1" >
                                                            <span style="font-size: 14px;">Secondary Owners</span>
                                                            <span class="add-bn" (click)="openUserForKR(i,j,'secondary')" title="Add Secondary Owners">+</span>
                                                            <br>
                                                            <span style="font-size: 12px;" *ngFor="let members of kr?.edit_secondary_owners; let memIndx = index">
                                                                <span *ngIf="memIndx == 0">{{members.first_name}} {{members.last_name}}</span>
                                                                <span *ngIf="memIndx != 0">, {{members.first_name}} {{members.last_name}}</span>
                                                            </span>
                                                            <!-- <span *ngIf="objective?.edit_primary_owners > 3" style="color: #4C78CE; font-style: italic; cursor: pointer;font-size:12px" (click)="openUser(i)">, + {{objective?.edit_primary_owners.length - 3}} more</span> -->
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="col-md-3 ml-auto"
                                                            style="display: flex; justify-content: space-between;">
                                                            <button (click)="cancel(i, j)" mat-flat-button
                                                                class="border bg-light">Cancel
                                                            </button>
                                                            <button (click)="update(i, j)" mat-flat-button class=""
                                                                color="primary">Ok
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </h2>
                                            <div id="collapse{{i}}{{j}}" class="accordion-collapse collapse"
                                                [attr.aria-labelledby]="'heading' + i + j"
                                                [attr.data-bs-parent]="'#accordion' + i">
                                                <div class="accordion-body">
                                                    <div *ngFor="let goal of kr?.goals; let k = index"
                                                        style="padding: 10px; position: relative; background: #f2f2f2; border-radius: 2px; margin-bottom: 10px;">
                                                        <div *ngIf="!goal.edit">
                                                            <span style="display: block;
                                                    font-size: 13px; "><b>{{goal.goal_name}}</b></span>
                                                            <span style="display: block;
                                                    font-size: 12px;
                                                    padding-left: 10px; font-style: italic">{{goal.goal_description}}
                                                                <span *ngIf="goal.end_date"><br> End Date:
                                                                    {{goal.end_date | date: 'mediumDate'}}</span>
                                                            </span>
                                                            <div style="background-color: #e0dbdb;padding: 4px;">
                                                                <p class="mt-2 ml-1 mb-1"
                                                                    style="font-size: 12px; font-weight: 500; text-decoration: underline">Measure of
                                                                    Success</p>
                                                                <mat-chip-set *ngFor="let mositem of goal.instancemetrics;let y = index">
                                                                    <span style="font-size:12px;">{{y+1}}. </span>
                                                                    <mat-chip>
                                                                        {{mositem.instancemetrics__name}}
                                                                        <span class="tgt">Target: {{mositem?.target_value}}</span>
                                                                        <span class="tgt" *ngIf="mositem.instancemetrics__metrics_calculation__name">Formula: {{mositem?.instancemetrics__metrics_calculation__name}}</span>
                                                                        <span class="tgt" *ngIf="mositem.instancemetrics__metrics_unit__name">Unit: {{mositem?.instancemetrics__metrics_unit__name}}</span>
                                                                    </mat-chip><br>
                                                                </mat-chip-set>
                                                            </div>
                                                            <hr>
                                                            <app-tag [dataObj]="getTagObject(goal.id)"
                                                                [tagsIds]="goal?.tag_ids"></app-tag>
                                                        </div>

                                                        <span *ngIf="!goal.edit" class="edit-icon">
                                                            <i class="fa fa-edit"
                                                                style="font-size: 13px; margin-bottom: 5px"
                                                                (click)="edit(i, j, k);$event.preventDefault();$event.stopPropagation();"></i>
                                                            <img src="./assets/images/comment-delete.svg"
                                                                (click)="removeGoals(i, j, k)" style="width:12px;">
                                                        </span>

                                                        <div class="p-3" *ngIf="goal.edit">
                                                            <mat-form-field appearance="outline" class="w-100">
                                                                <mat-label>Goal Name</mat-label>
                                                                <input type="text" matInput fullWidth status="basic"
                                                                    [(ngModel)]="goal.edit_goal_name"
                                                                    [ngModelOptions]="{standalone: true}">
                                                            </mat-form-field>

                                                            <mat-form-field appearance="outline" class="w-75 pr-1">
                                                                <mat-label>Goal Description</mat-label>
                                                                <input type="text" matInput style="font-size: 12px"
                                                                    [(ngModel)]="goal.edit_goal_description"
                                                                    [ngModelOptions]="{standalone: true}">
                                                            </mat-form-field>

                                                            <mat-form-field appearance="outline" class="w-25 pl-1">
                                                                <mat-label>End date</mat-label>
                                                                <input matInput class="form-control"
                                                                    [matDatepicker]="picker" readonly class="fw-500"
                                                                    [(ngModel)]="goal.edit_goal_date"
                                                                    [ngModelOptions]="{standalone: true}" fullWidth
                                                                    status="basic" (click)="picker.open()"
                                                                    name="edit_goal_date">
                                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                                </mat-datepicker-toggle>
                                                                <mat-datepicker #picker>
                                                                </mat-datepicker>
                                                            </mat-form-field>
                                                                <p class="mt-2 ml-1 mb-1"
                                                                    style="font-size: 12px; font-weight: 500;">Measure of
                                                                    Success   <span [matMenuTriggerFor]="tagMenu" class="add-bn" (click)="setMosCount(goal?.instancemetrics?.length,goal?.id)">+</span></p>
                                                                <mat-chip-set aria-label="Measure of Success" *ngFor="let mositem of goal.instancemetrics;let x= index">
                                                                    <span style="font-size:12px;">{{x+1}}. </span>
                                                                    <mat-chip>
                                                                        {{mositem.instancemetrics__name}}
                                                                        <span class="tgt">Target: {{mositem?.target_value}}</span>
                                                                        <span class="tgt" *ngIf="mositem.instancemetrics__metrics_calculation__name">Formula: {{mositem?.instancemetrics__metrics_calculation__name}}</span>
                                                                        <span class="tgt" *ngIf="mositem.instancemetrics__metrics_unit__name">Unit: {{mositem?.instancemetrics__metrics_unit__name}}</span>
                                                                        <mat-icon title="Delete" (click)="removeMOS(i, j, k,mositem)" style="color:red;font-size:16px;margin: 4px 0 0 4px;">delete</mat-icon>
                                                                    </mat-chip><br>
                                                                    <!-- <mat-chip [matMenuTriggerFor]="tagMenu" *ngIf="goal?.mos?.length == 0">
                                                                        Add Measure of Success
                                                                    </mat-chip> -->
                                                                </mat-chip-set>
                                                               
                                                            
                                                            <mat-menu #tagMenu="matMenu">
                                                                <div class="tag-pop" style="height: auto;max-height: 300px;">
                                                                    <!-- <div class="p-2">
                                                                <mat-chip-list #chipTagListDetail aria-label="tag selection" class="mb-2">
                                                                    <mat-chip *ngFor="let tag of mosList" (removed)="removeTag(tag, $event)">
                                                                        {{tag.tag_name}} 
                                                                        <button matChipRemove *ngIf="!user?.is_vendor">
                                                                            <mat-icon>cancel</mat-icon>
                                                                        </button>
                                                                    </mat-chip>
                                                                </mat-chip-list>
                                                                <p *ngIf="associatedTags.length <= 0" class="no-tag m-0">No tag added yet</p>
                                                            </div> -->
                                                                    <div class="search-input-holder">
                                                                        <span class="mr-2">{{MosCount}}. </span>
                                                                        <input type="text" class="search-key"
                                                                            placeholder="Search or Create new" matInput
                                                                            [(ngModel)]="mosInput"
                                                                            (ngModelChange)="searchInput($event)"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            (click)="$event.stopPropagation()">
                                                                    </div>
                                                                    <!-- <div class="flex-container" *ngIf="!user?.is_vendor">
                                                                <mat-chip *ngIf="selectedCategory&&filteredCategoryList.length==0" class="selectedTag"   >
                                                                    {{selectedCategory.tag_category}} 
                                                                   
                                                                </mat-chip>
                                                                <mat-chip [ngClass]="selectedCategory&&selectedCategory.tag_category==tag.tag_category? 'selectedTag' : 'unselectedTag'"  *ngFor="let tag of filteredCategoryList" (click)="selectCategory(tag)">
                                                                    {{tag.tag_category}} 
                                                                   
                                                                </mat-chip>
                                    
                                                            </div> -->
                                                                    <div style="height:auto;overflow: auto;" *ngIf="!editItem" >
                                                                            <ul class="p-0"  *ngIf="filteredMosList?.length > 0">
                                                                                <li class="tag-item px-1">
                                                                                    <div class="row mx-1"> 
                                                                                        <div class="col-sm-7"><p class="m-0" style="font-size: 12px; font-weight: 500">MOS</p></div>
                                                                                        <!-- <div class="col-sm-3 text-center"><p class="m-0" style="font-size: 12px; font-weight: 500">Target Value</p></div> -->
                                                                                        <div class="col-sm-3 text-center"><p class="m-0" style="font-size: 12px; font-weight: 500">Formula</p></div>
                                                                                        <div class="col-sm-2 text-center"><p class="m-0" style="font-size: 12px; font-weight: 500">Unit</p></div>
                                                                                    </div>
                                                                                    
                                                                                </li>
                                                                                <li *ngFor="let item of filteredMosList; let a = index;"
                                                                                    class="tag-item px-1"  (click)="editMos(item);$event.stopPropagation()">
                                                                                    <div class="row mx-1"> 
                                                                                        <div class="col-sm-7" style="word-wrap: break-word">{{item.display_name || item.name}}</div>
                                                                                        <!-- <div class="col-sm-3 text-center">
                                                                                            {{item?.target_value}}
                                                                                        </div> -->
                                                                                        <div class="col-sm-3">{{item?.metrics_calculation__name}}</div>
                                                                                         <div class="col-sm-2">{{item?.metrics_unit__name}}</div>
                                                                                        <!--    <i
                                                                                            class="fa fa-edit delete-item"
                                                                                            (click)="editMos(item);$event.stopPropagation()"></i>
                                                                                         -->
                                                                                    </div>
                                                                                    
                                                                                </li>
                                                                                
                                                                            </ul>
                                                                    </div>
                                                                    <div *ngIf="filteredMosList?.length == 0 || editItem"
                                                                        class="mt-2 mb-2 tag-not-found">
                                                                        <div class="row mx-2 mt-2">
                                                                            <div class="col-sm-4" style="display: flex; align-items: center;">
                                                                                <label class="m-0">MOS Title</label>
                                                                            </div>
                                                                            <div class="col-sm-8">
                                                                                <input type="text" class="form-control" [(ngModel)]="mosInput" style="font-size: 12px;"
                                                                                [ngModelOptions]="{standalone: true}" (click)="$event.stopPropagation()">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mx-2 mt-2">
                                                                            <div class="col-sm-4" style="display: flex; align-items: center;">
                                                                                <label class="m-0">Target Value</label>
                                                                            </div>
                                                                            <div class="col-sm-8">
                                                                                <input type="text" class="form-control" placeholder="Enter Number" [(ngModel)]="mosInputTarget" style="font-size: 12px;"
                                                                                [ngModelOptions]="{standalone: true}" (click)="$event.stopPropagation()">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mx-2 mt-2">
                                                                            <div class="col-sm-4" style="display: flex; align-items: center;">
                                                                                <label class="m-0">Formula</label>
                                                                            </div>
                                                                            <div class="col-sm-8">
                                                                                <select type="text" class="form-control" [(ngModel)]="calculation_mos" style="font-size: 12px;"
                                                                                [ngModelOptions]="{standalone: true}" (click)="$event.stopPropagation()">
                                                                                <option value="">--Select Formula--</option>
                                                                                <option *ngFor="let formula of formulaList" [value]="formula?.id">{{formula?.display_name}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row mx-2 mt-2">
                                                                            <div class="col-sm-4" style="display: flex; align-items: center;">
                                                                                <label class="m-0">Unit</label>
                                                                            </div>
                                                                            <div class="col-sm-8">
                                                                                <select type="text" class="form-control" [(ngModel)]="unit" style="font-size: 12px;"
                                                                                [ngModelOptions]="{standalone: true}" (click)="$event.stopPropagation()" >
                                                                                <option value="">--Select Unit--</option>
                                                                                    <option *ngFor="let unit of unitList" [value]="unit?.id">{{unit?.display_name}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div style="display: flex; justify-content: center;" class="p-1">
                                                                            <button (click)="createMos(i, j, k)" mat-flat-button color="primary"> 
                                                                                <span *ngIf="editItem"> Update </span>
                                                                                <span *ngIf="!editItem"> Create </span>
                                                                                 MOS </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </mat-menu>

                                                            <div class="row mt-2">
                                                                <div class="col-md-3 ml-auto"
                                                                    style="display: flex; justify-content: space-between;">
                                                                    <button (click)="cancel(i, j, k)" mat-flat-button
                                                                        class="border bg-light">Cancel
                                                                    </button>
                                                                    <button (click)="update(i, j, k)" mat-flat-button
                                                                        class="" color="primary">Ok
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a class="add-btton" (click)="addGoalsItems(i, j)">
                                                        <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                            class="cursor-pointer fs-5">add
                                                        </mat-icon>
                                                        Add Goals
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="add-btton" (click)="addKeyResultItems(i)">
                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                class="cursor-pointer fs-5">add
                                            </mat-icon>
                                            Add Keyresults
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a class="add-btton" (click)="addObjectiveItems()">
                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                class="cursor-pointer fs-5">add
                            </mat-icon>
                            Add Objective
                        </a>
                    </div>






                    <!-- <div class="custom-table">
                        <table class="table table-bordered">
                            <thead style="position: sticky;top: 0; z-index: 1">
                                <tr>
                                    <th scope="col" class="fixed-header">#</th>
                                    <th scope="col" class="w-100 fixed-header">Objective</th>
                                    <th class="text-center col-2 fixed-header" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let objective of createForm?.value; let i = index">
                                    <td>{{i+1}}</td>
                                    <td class="align-middle w-100">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label class="label-design"></mat-label>
                                                    <input type="text" matInput fullWidth status="basic" placeholder="title"
                                                    [(ngModel)]="objective.title" [ngModelOptions]="{standalone: true}">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-4">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label class="label-design"></mat-label>
                                                    <input type="text" matInput fullWidth status="basic" placeholder="description"
                                                    [(ngModel)]="objective.description" [ngModelOptions]="{standalone: true}">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-3">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label class="label-design"></mat-label>
                                                    <input matInput
                                                    [matDatepicker]="picker" readonly class="fw-500"
                                                    [(ngModel)]="objective.end_date" [ngModelOptions]="{standalone: true}" fullWidth
                                                    status="basic" (click)="picker.open()" name="end_date_name">
                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #picker>
                                                    </mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <table class="table table-bordered mb-2">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col" class="w-100">Key Result</th>
                                                    <th class="text-center col-2" scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let kr of objective?.key_results; let j = index">
                                                    <td>{{j+1}}</td>
                                                    <td class="align-middle w-100">
                                                        <div class="row">
                                                            <div class="col-md-5">
                                                                <mat-form-field appearance="outline" class="w-100">
                                                                    <mat-label class="label-design"></mat-label>
                                                                    <input type="text" matInput fullWidth status="basic" placeholder="title"
                                                                    [(ngModel)]="kr.title" [ngModelOptions]="{standalone: true}">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <mat-form-field appearance="outline" class="w-100">
                                                                    <mat-label class="label-design"></mat-label>
                                                                    <input type="text" matInput fullWidth status="basic" placeholder="description"
                                                                    [(ngModel)]="kr.description" [ngModelOptions]="{standalone: true}">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <mat-form-field appearance="outline" class="w-100">
                                                                    <mat-label class="label-design"></mat-label>
                                                                    <input matInput
                                                                    [matDatepicker]="picker" readonly class="fw-500"
                                                                    [(ngModel)]="kr.end_date" [ngModelOptions]="{standalone: true}" fullWidth
                                                                    status="basic" (click)="picker.open()" name="end_date_name">
                                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker>
                                                                    </mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                        <table class="w-100 mb-2">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col" class="w-100">Goals</th>
                                                                    <th class="text-center col-2" scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let goal of kr?.goals; let k = index">
                                                                    <td>{{k+1}}</td>
                                                                    <td>
                                                                        <div class="row">
                                                                            <div class="col-md-6">
                                                                                <mat-form-field appearance="outline" class="w-100">
                                                                                    <mat-label class="label-design"></mat-label>
                                                                                    <input type="text" matInput fullWidth status="basic" placeholder="title"
                                                                                    [(ngModel)]="goal.goal_name" [ngModelOptions]="{standalone: true}">
                                                                                </mat-form-field>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <mat-form-field appearance="outline" class="w-100">
                                                                                    <mat-label class="label-design"></mat-label>
                                                                                    <input type="text" matInput fullWidth status="basic" placeholder="description"
                                                                                    [(ngModel)]="goal.goal_description" [ngModelOptions]="{standalone: true}">
                                                                                </mat-form-field>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div style="display: flex; align-items: center; justify-content: space-around;">
                                                                            <span class="cursor-pointer" (click)="removeGoals(i, j, k)">
                                                                                <span class="cursor-pointer">
                                                                                    <img src="./assets/images/comment-delete.svg"
                                                                                        style="width:16px;">
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td class="w-50">
                                                                        <a class="add-btton"
                                                                            (click)="addGoalsItems(i, j)">
                                                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                                                class="cursor-pointer fs-5">add
                                                                            </mat-icon>
                                                                            Add Goals
                                                                        </a>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td>
                                                        <div style="display: flex; align-items: center; justify-content: space-around;">
                                                            <span class="cursor-pointer" (click)="removeKeyResult(i, j)">
                                                                <span class="cursor-pointer">
                                                                    <img src="./assets/images/comment-delete.svg"
                                                                        style="width:16px;">
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td class="w-50">
                                                        <a class="add-btton"
                                                            (click)="addKeyResultItems(i)">
                                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                                class="cursor-pointer fs-5">add
                                                            </mat-icon>
                                                            Add Key Result
                                                        </a>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>
                                        <div style="display: flex; align-items: center; justify-content: space-around;">
                                            <span class="cursor-pointer" (click)="removeObjective(i)">
                                                <span class="cursor-pointer">
                                                    <img src="./assets/images/comment-delete.svg"
                                                        style="width:16px;">
                                                </span>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="w-50">
                                        <a class="add-btton"
                                            (click)="addObjectiveItems()">
                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                class="cursor-pointer fs-5">add
                                            </mat-icon>
                                            Add Objective
                                        </a>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </form>
                <div class="row mt-2">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-6 text-right">
                        <button mat-button class="fs-16 next-color text-white" (click)="saveOkr()">
                            Save
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-sm-3">
            <div class="single-right-pane">
                <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
                <app-gnews></app-gnews>
                <!-- <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                            style="font-size: 13px">
                            Curated from Web
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body p-2">
                            <app-loading *ngIf="loading"></app-loading>
                            <app-gnews [display_header]="'false'"></app-gnews>
                        </div>
                    </div>
                </div>    -->
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>