import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IndexComponent } from './index/index.component';

const routes: Routes = [
  {
    path: 'okr-register',
    data: { breadcrumb: "OKR Register" },
    component: IndexComponent
  },
  {
    path:'',data: { breadcrumb:"OKR Convergence" },
    component:DashboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OkrConvergenceRoutingModule { }
