import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { OkrConvergenceRoutingModule } from './okr-convergence-routing.module';
import { IndexComponent } from './index/index.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChartModule } from 'angular-highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatRadioModule } from '@angular/material/radio';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { MatChipsModule } from '@angular/material/chips';
import { InsightComponent } from './dashboard/insight/insight.component';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
  declarations: [
    IndexComponent,
    DashboardComponent,
    InsightComponent
  ],
  imports: [
    CommonModule,
    OkrConvergenceRoutingModule,
    SharedModule,
    ChartModule,
    HighchartsChartModule,
    MaterialmodulesModule,AngularEditorModule,NgbModule,
    MatRadioModule,
    NgxPermissionsModule,
    PowerBIEmbedModule,
    MatChipsModule,
    MatSelectModule,
    
  ]
})
export class OkrConvergenceModule { }
