
<div class="d-flex mb-3" style="align-items: center; justify-content: space-between;">
    <h6>Progress Summary</h6>
    <div style="display:flex;" *ngIf="selectedTab">
        <div class="insight-button" [ngStyle]="ytd ? {'background-color': '#051e5f', 'color': '#fff'} : {}" (click)="setYTD()">
            <div>YTD</div>
        </div>
        <div class="ml-2 insight-button-select" [ngStyle]="ltm ? {'background-color': '#051e5f', 'color': '#fff'} : {}" (click)="setLTM()">
            <div>LTM</div>
        </div>
        <div class="ml-2">
            <select class="insight-button-select"  [(ngModel)]="setYear" (ngModelChange)="yearChange()">
                <option value="">Year</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
            </select>
        </div>
        <div class="ml-2">
            <select class="insight-button-select" [(ngModel)]="setMonth" (ngModelChange)="monthChange()">
                <option value="">Month</option>
                <option value=1>Jan</option>
                <option value=2>Feb</option>
                <option value=3>Mar</option>
                <option value=4>Apr</option>
                <option value=5>May</option>
                <option value=6>Jun</option>
                <option value=7>Jul</option>
                <option value=8>Aug</option>
                <option value=9>Sep</option>
                <option value=10>Oct</option>
                <option value=11>Nov</option>
                <option value=12>Dec</option>
            </select>
        </div>
        
    </div>
    <div class=" cancle-btn-in float-right" (click)="changeTab()">
        <mat-icon *ngIf="selectedTab">arrow_forward</mat-icon>
        <mat-icon *ngIf="!selectedTab">arrow_back</mat-icon>
    </div>
</div>
<div *ngIf="selectedTab">
    <app-okr-listing [OKRList]="OKRList"></app-okr-listing>
</div>
<div *ngIf="!selectedTab">
    <powerbi-report [embedConfig]="insightModel" [cssClassName]="reportClass"></powerbi-report>
</div>

                