import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { OkrConvergenceService } from 'src/app/shared/services/okr-convergence.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { GetRandomColorPipe } from "../../../shared/pipes/get-random-color.pipe";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { DatePipe } from '@angular/common';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import moment from 'moment';
import { MessageService } from 'src/app/shared/services/message.service';
import { FilterService } from 'src/app/shared/services/filter.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  providers: [GetRandomColorPipe,DatePipe],
  encapsulation: ViewEncapsulation.Emulated
})
export class IndexComponent implements OnInit {

  createForm: FormArray | any;

  deleted_objectives: any = [];
  deleted_key_results: any = [];
  deleted_goals: any = [];
  staticText: any = (textConfiguration as any).default;
  mosList: any;
  mosInput: string;
  filteredMosList: any = [];
  mosInputTarget: any;
  calculation_mos:any;
  unit:any;
  editItem: boolean = false;
  mosSelectedId: any;
  loading:any = false;
  userSearchText: any = "";
  userList:any = [];
  formulaList:any = [];
  unitList:any = [];
  MosCount:Number = 0;
  pagetype = 'OKR-register';
  params: any;
  private unsubscribe$ = new Subject<void>();
  filterObj:any;
  filterField: any;
  selectedGoalId:any;
  constructor(
    private ProgramService: ProgramService,
    private okrConvergenceService: OkrConvergenceService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private randomColor: GetRandomColorPipe,
    private permissionsService: NgxPermissionsService,
    private dialog: MatDialog,
    private _formValidator:FormValidatorService,
    private renderer: Renderer2,
    private date:DatePipe,
    private messageService: MessageService,
    private filterService: FilterService
  ) { 
  
  }

  ngOnInit(): void {
    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem('permission') || '{}').permissions);
    this.createForm = this.formBuilder.array([]);
    this.getMos();
    //this.getMaster();
    this.getUserList();
    this.getFormulaList();
    this.getUnitList();
    let firstTime:boolean = true;
    this.messageService.setGnews('OKR Heatmap')
    this.filterService.setValue('');
    this.filterService.filterSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if(this.filterObj[element]?.array?.length > 0){
          awl = true;
          return;
        }
      });
      if(firstTime && awl == false){
        this.getMaster();
        firstTime = false;
      }
      if(awl){
        this.getMaster();
        firstTime = true;
      }
    })
    this.filterService.filterFlag.subscribe((ele:any)=>{
      this.filterField = ele.field;
    })
  }

  getUserList(){
    this.okrConvergenceService.getUser().subscribe((res: any) => {
      res.forEach((element:any) => {
        if(element.first_name || element.last_name){
          element.name = element.first_name+' '+element.last_name;
          this.userList.push(element)
        }
      });
    })
  }

  openUserForObj(i: any, type:any,bool: any = true){
    let option_type = '';
    if(type== 'primary'){
      this.userList.forEach((element: any) => {
         element.selected = this.createForm?.value[i]?.edit_primary_owners?.filter((e: any) => e.id == element.id).length > 0 ? true : false;
      });
      option_type = "Primary Owners";
    }else{
      this.userList.forEach((element: any) => {
        element.selected = this.createForm?.value[i]?.edit_secondary_owners?.filter((e: any) => e.id == element.id).length > 0 ? true : false;
     });
     option_type = "Secondary Owners";
    }

      const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
      dialog.componentInstance.type = {
        "name":"user_owner",
        "section":"user_owner",
        "respondents": [],
        "team_list": this.userList,
        "filterTeamList": this.userList,
        "allowed": bool,
        "header_name":option_type
      };
      dialog.afterClosed().subscribe(val => {
        if(val){
          let selected = val?.filter((e: any) => e.selected == true)
          if(type== 'primary'){
            this.createForm.value[i].edit_primary_owners = selected;
          }else{
            this.createForm.value[i].edit_secondary_owners = selected;
          }
        }
      })
    
  }

  openUserForKR(i: any,j:any, type:any,bool: any = true){
    let option_type = '';
    if(type== 'primary'){
      this.userList.forEach((element: any) => {
         element.selected = this.createForm?.value[i]?.key_results[j]?.edit_primary_owners?.filter((e: any) => e.id == element.id).length > 0 ? true : false;
      });
      option_type = "Primary Owners";
    }else{
      this.userList.forEach((element: any) => {
        element.selected = this.createForm?.value[i]?.key_results[j]?.edit_secondary_owners?.filter((e: any) => e.id == element.id).length > 0 ? true : false;
     });
     option_type = "Secondary Owners";
    }

      const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
      dialog.componentInstance.type = {
        "name":"user_owner",
        "section":"user_owner",
        "respondents": [],
        "team_list": this.userList,
        "filterTeamList": this.userList,
        "allowed": bool,
        "header_name":option_type
      };
      dialog.afterClosed().subscribe(val => {
        if(val){
          let selected = val?.filter((e: any) => e.selected == true)
          if(type== 'primary'){
            this.createForm.value[i].key_results[j].edit_primary_owners = selected;
          }else{
            this.createForm.value[i].key_results[j].edit_secondary_owners = selected;
          }
        }
      })
    
  }

  searchUser(item: any, search: any){
    if(search != ""){
      return item?.name?.toLowerCase().includes(search?.toLowerCase())
    }else{
      return true;
    }
  }

  getMos(){
    this.okrConvergenceService.getMosMaster().subscribe((res: any) => {
      this.mosList = res;
      this.filteredMosList = this.mosList;
    })
  }

  getMaster(){
    this.spinner.show();
    let queryparam:string = '';
    this.createForm.value = []
    if(this.filterObj){
      Object.keys(this.filterObj).forEach((key:any) => {
        this.filterObj[key].array.forEach((element:any) => {
          switch (key) {
            case "okrobj":
              queryparam = queryparam+ '&obj_id='+element.id;
              break;
            case "okrkr":
              queryparam = queryparam+ '&key_id='+element.id;
              break;
            case "okrgoal":
              queryparam = queryparam+ '&goal_id='+element.id;
              break;
            case "tag":
              queryparam = queryparam+ '&tag_id='+element.id;
              break;
            case "okrPrimaryOwner":
              queryparam = queryparam+ '&obj_primary_owner='+element.id;
              break;
            case "okrSecondaryOwner":
              queryparam = queryparam+ '&obj_support_owner='+element.id;
              break;      
            case "okrEndDate":
              queryparam = queryparam+ '&end_date='+element;
              break; 
            case "okrMOS":
              queryparam = queryparam+ '&mos_id='+element.id;
              break; 
            default:
              break;
          }
        });
      });
    }
  
    this.okrConvergenceService.getGoalMaster(queryparam).subscribe((res: any) => {
      res.okr_hierarchy.forEach((obj: any) => {
        this.createForm.value.push(obj);
      });
    
      this.createForm.value.forEach((obj: any) => {
        obj.end_date = obj.end_date ? new Date(obj.end_date) : "";

        obj.key_results.forEach((kr: any) => {
          kr.end_date = kr.end_date ? new Date(kr.end_date) : "";

          kr.goals.forEach((goal: any) => {
            goal.end_date = goal.end_date ? new Date(goal.end_date) : "";
          });
        });
      });
      
      this.spinner.hide();
    })
  }

  addObjectiveItems(){
    this.createForm.value.push({
      title: "",
      description: "",
      end_date: ""
    })
    this.edit(this.createForm.value.length - 1)
  }

  addKeyResultItems(i: any){
    if(!this.createForm.value[i].key_results){
      this.createForm.value[i].key_results = [];
    }
    
    this.createForm.value[i].key_results.push({
      title: "",
      description: "",
      end_date: ""
    })

    this.edit(i, this.createForm.value[i].key_results.length - 1)
  }

  addGoalsItems(i: any, j: any){
    if(!this.createForm.value[i].key_results[j].goals){
      this.createForm.value[i].key_results[j].goals = [];
    }
   
    this.createForm.value[i].key_results[j].goals.push({
      goal_name: "",
      goal_description: "",
      tagIds: "",
      end_date: "",
      instancemetrics: []
    })

    this.edit(i, j, this.createForm.value[i].key_results[j].goals.length - 1)
  }

  removeObjective(i: any){
    if(this.createForm.value[i].id){
      this.deleted_objectives.push(this.createForm.value[i].id);
    }
    
    this.createForm.value.splice(i, 1)
  }

  removeKeyResult(i: any, j: any){
    if(this.createForm.value[i].key_results[j].id){
      this.deleted_key_results.push(this.createForm.value[i].key_results[j].id);
    }

    this.createForm.value[i].key_results.splice(j, 1)
  }

  removeGoals(i: any, j: any, k: any){
    if(this.createForm.value[i].key_results[j].goals[k].id){
      this.deleted_goals.push(this.createForm.value[i].key_results[j].goals[k].id);
    }

    this.createForm.value[i].key_results[j].goals.splice(k, 1)
  }

  saveOkr(){
    this.spinner.show();
    this.createForm.value.forEach((obj: any) => {
      let primary_owners:any = [];
      let support_owners:any = [];
      obj.end_date = obj.end_date ? moment(obj.end_date).format("YYYY-MM-DD") : null;

      if(obj.key_results){
        obj.key_results.forEach((kr: any) => {
          let kr_primary_owners:any = [];
          let kr_support_owners:any = [];
          kr.end_date = kr.end_date ? moment(kr.end_date).format("YYYY-MM-DD") : null;

          if(kr.goals){
            kr.goals.forEach((goal: any) => {
              goal.end_date = goal.edit_goal_date ? moment(goal.edit_goal_date).format("YYYY-MM-DD") : null;
            });
          }
          kr.primary_owners.forEach((val:any)=>{
            kr_primary_owners.push(val?.id)
          });
          kr.support_owners.forEach((val:any)=>{
            kr_support_owners.push(val?.id)
          });
          kr.primary_owners = kr_primary_owners;
          kr.support_owners = kr_support_owners;
        });
      }
      obj.primary_owners.forEach((val:any)=>{
        primary_owners.push(val?.id)
      });
      obj.support_owners.forEach((val:any)=>{
        support_owners.push(val?.id)
      });
      obj.primary_owners = primary_owners;
      obj.support_owners = support_owners;
    });
    let payload = {
      objectives: this.createForm.value,
      deleted_objectives: this.deleted_objectives,
      deleted_key_results: this.deleted_key_results,
      deleted_goals: this.deleted_goals
    }
    this.okrConvergenceService.updateOkr(payload).subscribe((res: any) => {
      this.createForm = this.formBuilder.array([]);
      this.getMaster();
      this.toastrService.success("OKR updated")
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "okr"
    }
  }

  edit(i: any = undefined, j: any = undefined, k: any = undefined){
    this.createForm.value.forEach((iind: any) => {
      iind.edit = false;
      iind?.key_results?.forEach((krind: any) => {
        krind.edit = false;
        krind?.goals?.forEach((goind: any) => {
          goind.edit = false;
        });
      });
    });
    if(k != undefined){
      this.createForm.value[i].key_results[j].goals[k].edit = true;
      this.createForm.value[i].key_results[j].goals[k].edit_goal_name = this.createForm.value[i].key_results[j].goals[k].goal_name;
      this.createForm.value[i].key_results[j].goals[k].edit_goal_description = this.createForm.value[i].key_results[j].goals[k].goal_description;
      this.createForm.value[i].key_results[j].goals[k].edit_goal_date = this.createForm.value[i].key_results[j].goals[k].end_date;
    }else if(j != undefined){
      this.createForm.value[i].key_results[j].edit = true;
      this.createForm.value[i].key_results[j].edit_title = this.createForm.value[i].key_results[j].title;
      this.createForm.value[i].key_results[j].edit_end_date = this.createForm.value[i].key_results[j].end_date;
      this.createForm.value[i].key_results[j].edit_primary_owners = this.createForm.value[i].key_results[j].primary_owners;
      this.createForm.value[i].key_results[j].edit_secondary_owners = this.createForm.value[i].key_results[j].support_owners;

    }else{
      this.createForm.value[i].edit = true;
      this.createForm.value[i].edit_title = this.createForm.value[i].title;
      this.createForm.value[i].edit_end_date = this.createForm.value[i].end_date;
      this.createForm.value[i].edit_primary_owners = this.createForm.value[i].primary_owners;
      this.createForm.value[i].edit_secondary_owners = this.createForm.value[i].support_owners;

    }
    
  }

  update(i: any = undefined, j:any = undefined, k:any = undefined){
    this.createForm.value.forEach((iind: any) => {
      iind.edit = false;
      iind?.key_results?.forEach((krind: any) => {
        krind.edit = false;
        krind?.goals?.forEach((goind: any) => {
          goind.edit = false;
        });
      });
    });
    if(k != undefined){
      this.createForm.value[i].key_results[j].goals[k].edit = false;
      this.createForm.value[i].key_results[j].goals[k].goal_name = this.createForm.value[i].key_results[j].goals[k].edit_goal_name;
      this.createForm.value[i].key_results[j].goals[k].goal_description = this.createForm.value[i].key_results[j].goals[k].edit_goal_description;
      this.createForm.value[i].key_results[j].goals[k].end_date = this.createForm.value[i].key_results[j].goals[k].edit_goal_date;
    }else if(j != undefined){
      this.createForm.value[i].key_results[j].edit = false;
      this.createForm.value[i].key_results[j].title = this.createForm.value[i].key_results[j].edit_title;
      this.createForm.value[i].key_results[j].end_date = this.createForm.value[i].key_results[j].edit_end_date;
      this.createForm.value[i].key_results[j].primary_owners = this.createForm.value[i].key_results[j].edit_primary_owners;
      this.createForm.value[i].key_results[j].support_owners = this.createForm.value[i].key_results[j].edit_secondary_owners;
    }else{
      this.createForm.value[i].edit = false;
      this.createForm.value[i].title = this.createForm.value[i].edit_title;
      this.createForm.value[i].end_date = this.createForm.value[i].edit_end_date;
      this.createForm.value[i].primary_owners = this.createForm.value[i].edit_primary_owners;
      this.createForm.value[i].support_owners = this.createForm.value[i].edit_secondary_owners;
    }

    this.saveOkr();
  }

  cancel(i: any, j: any, k: any){
    this.createForm.value.forEach((iind: any) => {
      iind.edit = false;
      iind?.key_results?.forEach((krind: any) => {
        krind.edit = false;
        krind?.goals?.forEach((goind: any) => {
          goind.edit = false;
        });
      });
    });
  }

  public searchInput(val: any) {
    const filterValue = val.toLowerCase();
    this.filteredMosList = this.mosList?.filter((option: any) => option?.name?.toLowerCase().indexOf(filterValue) === 0);
   
  }

  addMos(item: any, i: any, j: any, k:any){
    let index = this.createForm.value[i].key_results[j].goals[k].instancemetrics.findIndex((e: any) => e === item.id);
    if(index < 0){
      this.createForm.value[i].key_results[j].goals[k].instancemetrics.push(item.id)
    }
  }

  getMosInfo(item: any){
    return this.mosList.filter((e: any) => e.id === item)[0]
  }

  createMos(i: any, j: any, k: any){
    this.spinner.show();
    let payload:any;
    if(this.mosSelectedId){
     payload = {
      name: this.mosInput,
      display_name: this.mosInput,
      target_value: this.mosInputTarget,
      metrics_calculation_id:parseInt(this.calculation_mos),
      
      metrics_unit_id:parseInt(this.unit),
      goal_id:this.selectedGoalId,
      instancemetrics_id:this.mosSelectedId
     }
    }else{
      payload = {
        name: this.mosInput,
        display_name: this.mosInput,
        target_value: this.mosInputTarget,
        metrics_calculation_id:parseInt(this.calculation_mos),
        metrics_unit_id:parseInt(this.unit),
        goal_id:this.selectedGoalId,
    }
  }

    this.okrConvergenceService.createMosValue(payload, this.mosSelectedId).subscribe((res: any) => {
     
      this.mosInput = "";
      this.mosInputTarget = "";
      // if(this.mosSelectedId){
        // let indx = this.mosList.findIndex((e: any) => e.id == this.mosSelectedId)
        // this.mosList[indx] = res;
        this.mosSelectedId = "";
        this.calculation_mos = "";
        this.unit = "";
        this.editItem = false;
      // }else{
        // this.mosList.push(res);
        this.createForm.value[i].key_results[j].goals[k].instancemetrics = this.createForm.value[i].key_results[j].goals[k].instancemetrics.filter((e: any) => e.id !== res?.id)
        let json =  {
            id:res?.id,
            instancemetrics:res?.instancemetrics?.id,
            instancemetrics__metrics_calculation:res?.instancemetrics?.metrics_calculation?.id,
            instancemetrics__metrics_calculation__name:res?.instancemetrics?.metrics_calculation?.name,
            instancemetrics__metrics_unit:res?.instancemetrics?.metrics_unit?.id,
            instancemetrics__metrics_unit__name:res?.instancemetrics?.metrics_unit?.name,
            instancemetrics__name:res?.instancemetrics?.name,
            target_value:res?.target_value
          }
        this.createForm.value[i].key_results[j].goals[k].instancemetrics.push(json)
      // }
      this.searchInput("");
      
      
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  editMos(item: any){
    console.log(item);
    this.mosInput = item.name;
    this.mosInputTarget = item?.target_value;
    this.calculation_mos = parseInt(item.metrics_calculation);
    this.unit = parseInt(item.metrics_unit);
    this.mosSelectedId = parseInt(item.id);
    this.editItem = true;
    this.getUnitList();
    this.getFormulaList();
  }

  removeMOS(i:any,j:any,k:any,mos:any){
    this.okrConvergenceService.deleteMosValue(mos.id).subscribe((res: any) => {
      const index = this.createForm.value[i].key_results[j].goals[k].instancemetrics.indexOf(mos.id);
      this.createForm.value[i].key_results[j].goals[k].instancemetrics.splice(index, 1);
    
    })
  }

  async getFormulaList(){
   await this.okrConvergenceService.getBaseValue('mos_formula').subscribe((res: any) => {
      this.formulaList = res;
    })
  }

  async getUnitList(){
   await this.okrConvergenceService.getBaseValue('mos_unit').subscribe((res: any) => {
      this.unitList = res;
    })
  }

  setMosCount(count:number,goal_id:any){
    this.filteredMosList = this.mosList
    this.editItem = false;
    this.mosSelectedId = "";
    this.calculation_mos = "";
    this.unit = "";
    this.editItem = false;
    this.MosCount = count + 1;
    this.selectedGoalId = goal_id;
  }

}