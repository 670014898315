<div class="w-100 bg-lavendar mt-80">
  <app-header></app-header>
</div>
<div class="px-3 mb-4">
  <div class="py-3">
    <app-filter-card
      [pagetype]="pagetype"
      [queryParam]="params"
    ></app-filter-card>
  </div>
  <div class="pb-3">
    <app-bread-crumb></app-bread-crumb>
  </div>
  <div class="row">
    <div class="col-sm-2">
      <div class="single-left-pane">
        <app-side-menu></app-side-menu>
      </div>
    </div>
    <div class="col-sm-7">
      <div class="single-center-pane">
        <div
          class="d-flex mb-3"
          style="align-items: center; justify-content: space-between"
        >
          <h5>OKR Convergence</h5>
          <div style="display: flex">
            <div
              *ngFor="let item of accordionItemsLeft"
              class="insight-button"
              [ngClass]="{ 'insight-active': item.status === 'active' }"
              (click)="clickOnTab(item.tab)"
            >
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="justify-content-end">
            <button
              mat-button
              [routerLink]="['/okrconvergence/okr-register']"
              class="bg-primary text-white"
              mat-button
              mat-flat-button
              *ngxPermissionsOnly="[
                'opportunity.view_keyresultmeasureofsuccess',
                'opportunity.view_keyresults',
                'opportunity.view_keyresultcomments'
              ]"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="material-icons-filled "
                class="cursor-pointer fs-5"
                >add
              </mat-icon>
              {{ staticText.okr_convergence.okr_register }}
            </button>
          </div>
        </div>
        <div *ngIf="selectedTab?.tab == 1">
          <div class="table" style="margin-bottom: 0px !important">
            <div class="table-row">
              <div
                class="table-cell cursor-pointer fw-bold"
                style="
                  max-width: 150px;
                  min-width: 150px;
                  width: 150px;
                  font-size: 14px;
                  position: sticky;
                  left: 0;
                  z-index: 1;
                  height: 0;
                "
              >
                <div
                  class="domain"
                  style="
                    background-color: #0bb797;
                    border-radius: 10px;
                    height: 100%;
                  "
                >
                  Objectives
                </div>
              </div>
              <div class="table-cell table-nest">
                <div class="table-row" style="position: relative">
                  <div
                    class="table-cell cursor-pointer"
                    style="
                      max-width: 150px;
                      min-width: 150px;
                      font-size: 14px;
                      position: sticky;
                      left: 150px;
                      z-index: 1;
                      height: 0;
                    "
                  >
                    <div
                      class="subdomain"
                      style="
                        background-color: #0bb797;
                        border-radius: 10px;
                        padding: 8px;
                        height: 100%;
                      "
                    >
                      Key Results
                    </div>
                  </div>
                  <div
                    class="table-cell"
                    style="
                      max-width: 150px;
                      min-width: 150px;
                      position: relative;
                      padding: 5px;
                      cursor: pointer;
                    "
                  >
                    <span
                      style="
                        position: absolute;
                        font-size: 14px;
                        text-align: center;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 90%;
                        height: 100%;
                        padding: 7px 0;
                      "
                    >
                      <div
                        style="
                          background-color: #0bb797;
                          color: white;
                          border-radius: 10px;
                          padding: 8px;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        Goals
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="overflow: scroll; height: calc(100vh - 290px)">
            <div class="container-fluid p-0">
              <div class="table">
                <div
                  class="table-row"
                  *ngFor="let objective of okrHeatMapData; let subindex = index"
                >
                  <div
                    class="table-cell cursor-pointer fw-bold"
                    style="
                      max-width: 150px;
                      min-width: 150px;
                      font-size: 13px;
                      position: sticky;
                      left: 0;
                      z-index: 1;
                      height: 0;
                    "
                  >
                    <div
                      class="domain"
                      style="border-radius: 10px; height: 100%"
                    >
                      {{ objective?.title }}
                    </div>
                  </div>
                  <div
                    class="table-cell table-nest"
                    *ngIf="objective.key_results"
                  >
                    <div
                      class="table-row"
                      style="position: relative"
                      *ngFor="
                        let kr of objective?.key_results;
                        let subsubindex = index
                      "
                    >
                      <div
                        class="table-cell cursor-pointer"
                        id="subdomain_{{ subsubindex }}"
                        style="
                          max-width: 150px;
                          min-width: 150px;
                          font-size: 13px;
                          position: sticky;
                          left: 150px;
                          z-index: 1;
                          height: 0;
                        "
                      >
                        <div
                          class="subdomain"
                          style="
                            border-radius: 10px;
                            padding: 8px;
                            height: 100%;
                          "
                          [matTooltip]="kr?.title | titlecase"
                        >
                          {{ kr?.title }}
                        </div>
                      </div>
                      <div
                        class="table-cell"
                        style="
                          max-width: 150px;
                          min-width: 150px;
                          position: relative;
                          padding: 5px;
                          cursor: pointer;
                        "
                        *ngFor="let goal of kr?.goals; let goalsubindex = index"
                      >
                        <span
                          id="goalsubdomain_{{ subsubindex }}_{{
                            goalsubindex
                          }}"
                          style="
                            position: absolute;
                            font-size: 13px;
                            text-align: center;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 90%;
                            height: 100%;
                            padding: 5px 0;
                          "
                          *ngIf="goal.id"
                          (click)="showGoalInfo(goal)"
                        >
                          <div
                            [ngStyle]="{
                              backgroundColor:
                                selected == goal.id ? '#86da64' : '#c2bec7'
                            }"
                            [matTooltip]="goal?.goal_name | titlecase"
                            style="
                              border-radius: 10px;
                              padding: 0 10px;
                              padding-bottom: 30px;
                              height: 100%;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              font-size: 0.75rem;
                              text-align: left;
                            "
                          >
                            {{ getTrucatedName(kr?.title, goal?.goal_name) }}
                          </div>
                          <div
                            class="row"
                            style="
                              position: absolute;
                              bottom: 10px;
                              display: flex;
                              align-items: center;
                            "
                          >
                            <div
                              class="col-sm-6"
                              style="display: flex; align-items: center"
                              [matTooltip]="
                                '# of Opportunities Subscribing - ' +
                                goal.opportunity_count
                              "
                            >
                              <img
                                src="assets/images/project-icon.png"
                                alt="image"
                                width="30px"
                                style="
                                  background: #ffffff57;
                                  padding: 1px;
                                  border-radius: 14px;
                                "
                              />
                              <span
                                style="margin-left: 5px; font-weight: 900"
                                >{{ goal.opportunity_count }}</span
                              >
                            </div>
                            <div
                              class="col-sm-6"
                              style="
                                display: flex;
                                align-items: center;
                                padding-left: 25px;
                              "
                              [matTooltip]="
                                '# of projects contributing to Objectives, Key Results and Goals - ' +
                                goal.project_count
                              "
                            >
                              <img
                                src="assets/images/project-icon.png"
                                alt="image"
                                width="30px"
                                style="
                                  background: #ffffff57;
                                  padding: 4px;
                                  border-radius: 14px;
                                "
                              />
                              <span
                                style="
                                  margin-left: 10px;
                                  margin-left: 5px;
                                  font-weight: 900;
                                "
                                >{{ goal.project_count }}</span
                              >
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab?.tab == 2">
          <app-insight [OKRData]="okrHeatMapData"></app-insight>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="single-right-pane">
        <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
        <div *ngIf="selectedTab?.tab == 1">
          <h6>Insights & Engagement Zone</h6>
          <app-loading
            *ngIf="!oppoDataList && !projectDataList && loading"
            style="height: 90%; display: block"
          ></app-loading>
          <div class="norec" *ngIf="!oppoDataList && !loading">
            Select Goal to view projects
          </div>
          <div
            class="norec"
            *ngIf="oppoDataList && !loading"
            style="
              font-size: 12px;
              text-align: left;
              background-color: rgb(125, 228, 125);
              margin-bottom: 10px;
            "
          >
            {{ selectedGoalName }}
          </div>
          <div
            class="accordion"
            id="accordionExample"
            *ngIf="oppoDataList && projectDataList && !loading"
          >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingZero">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseZero"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style="font-size: 13px"
                >
                  Subscribing Opportunities
                </button>
              </h2>
              <div
                id="collapseZero"
                class="accordion-collapse collapse"
                aria-labelledby="headingZero"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body p-2">
                  <app-loading *ngIf="!oppoDataList"></app-loading>

                  <div
                    *ngIf="oppoDataList && oppoDataList.length == 0"
                    style="
                      font-size: 0.8em;
                      text-align: center;
                      margin-top: 10px;
                    "
                  >
                    No Opportunities found
                  </div>
                  <div
                    *ngFor="let project of oppoDataList"
                    [ngStyle]="{ background: project.color }"
                    class="impacted-okr-style p-2"
                  >
                    <span>#{{ project?.opportunity_number }}</span>
                    <div
                      class="cursor-pointer"
                      style="color: #000; font-size: 14px"
                      (click)="openOpportunity(project)"
                    >
                      {{ project?.opportunity_name }}
                    </div>
                    <span style="font-size: 12px">{{
                      project?.portfolio[0]?.name
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style="font-size: 13px"
                >
                  Contributing Projects
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body p-2">
                  <app-loading *ngIf="!projectDataList"></app-loading>

                  <div
                    *ngIf="projectDataList && projectDataList.length == 0"
                    style="
                      font-size: 0.8em;
                      text-align: center;
                      margin-top: 10px;
                    "
                  >
                    No Project found
                  </div>
                  <div
                    *ngFor="let project of projectDataList"
                    [ngStyle]="{ background: project.color }"
                    class="impacted-okr-style p-2"
                  >
                    <span>#{{ project?.project_id }}</span>
                    <div
                      class="cursor-pointer"
                      style="color: #000; font-size: 14px"
                      (click)="openProjects(project)"
                    >
                      {{ project?.project_name }}
                    </div>
                    <span style="font-size: 12px">{{
                      project?.portfolios[0]?.display_name
                    }}</span>
                  </div>
                  <!-- <div class="content-tree-holder mt-2" *ngIf="projectDataList">
                                    <app-tree-listing [items]="projectDataList" [tree]="project" [rightpane]="true"
                                        [context]="context"></app-tree-listing>
                                </div> -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  style="font-size: 13px"
                >
                  Mapped Applications
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body p-2">
                  <div
                    *ngFor="let app of applications"
                    [ngStyle]="{ background: app.color }"
                    class="impacted-okr-style p-2"
                  >
                    <div>{{ app?.name }}</div>
                  </div>

                  <div *ngIf="applications?.length == 0" class="text-center">
                    <span class="fs-12">{{
                      staticText.common.no_records_found
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  style="font-size: 13px"
                >
                  Curated From Web
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse show"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body p-2">
                  <app-gnews
                    [pagename]="pagename1"
                    [display_header]="display_header"
                  ></app-gnews>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab?.tab == 2">
          <!-- <div class="comingSoon text-center">
                <img src="assets/images/analytics_bg.png" style="width: 100%; height: calc(100vh - 50vh); object-fit: cover; object-position: -236px; ">
                <h6 style=" margin-top: 21px; ">Coming Soon !</h6>
            </div> -->
          <app-gnews></app-gnews>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<!-- <header class="okrconvergence-dashboard">
    <div class="w-100 bg-lavendar mt-80">
        <app-header></app-header>
    </div>
    <div class="d-flex flex-column">
        <div class="bg-cont-overlay">
            <div class="container-fluid container-xxl">
                <div class="row">
                    <div class="col">
                        <div class="text-right">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="okrconvergence-body">
    <div class="d-flex flex-column container-fluid container-xxl mb-3">
        <div class="margin-minus-50">
            <app-tabmenu [tabcontentdynamic]="CarouselFilterList" (clearFilterData)="clearFilter()" [carouselSpace]="10"
                [subportfolio]="subPortfilteredOptions" (portfolio)="selectedPortfolioValues($event)" (subportfolioId)="selectedSubportfolioValues($event)"
                 [projectList]="projectListData" (ProjectId)="selectedProjectId($event)">
            </app-tabmenu>
        </div>
        <div class="pt-4">

            <div class="container-fluid">
                <div class="row row-cols-2 d-flex ">
                    <div class="col-8">
                        <mat-card class="border-radius-15 pt-5 mt-20-heatmap">
                            <mat-card-content>
                                <div class="d-flex flex-column" >
                                    <h5 class="fsize40 fw-normal">{{staticText?.okr_heatmap.title}}
                                        <button class="OkrLog text-white text-capitalize float-right" mat-raised-button
                                            [routerLink]="['/okrconvergence/okr-register']"
                                            routerLinkActive="router-link-active"
                                            *ngxPermissionsOnly="['opportunity.view_keyresultmeasureofsuccess' , 'opportunity.view_keyresults' , 'opportunity.view_keyresultcomments']">
                                            {{staticText?.okr_convergence.okr_register}}
                                        </button>
                                    </h5>
                                    <p class="fs-18">{{staticText?.okr_heatmap.content}}</p>
                                    <ng-container *ngIf="okrHeatMapData?.length !== 0">
                                        <div class="treemap" [chart]="treeMapChart1">
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="row noData" *ngIf="okrHeatMapData?.length === 0">
                                    <div class="col-12 mx-auto">
                                        <img src="./assets/images/no-record.png" class="no-records"
                                            height="100%" width="100%">
                                    </div>
                                    <div class="col-8 mx-auto">
                                        <p class="text-center fw-bold text-muted">
                                            {{staticText.common.no_records_found}}
                                        </p>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-4">
                        <mat-card class="border-radius-15">

                            <mat-card-header class="kr">
                                <mat-card-title>{{staticText?.okr_heatmap.krs}}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="projectlist" *ngIf="showAllProjects">
                                    <div class="p-1 scroll-All-container">
                                        <div class="accordion accordion-flush" id="projectSection">
                                            <div class="pt-2 pb-2"
                                                *ngFor="let allprojectList of allOkrHeatMapProjects; index as allProjectIndex">
                                                <div class="accordion-item sectionOne">
                                                    <div class="accordion-header  cursor-pointer p-2 projectTitleCard"
                                                        [id]="'projectHeader'+allProjectIndex+1"
                                                        data-bs-toggle="collapse" aria-expanded="true"
                                                        [attr.data-bs-target]="'#projectSectionOneCollapse_'+allProjectIndex+1*22+13"
                                                        [attr.aria-controls]="'projectSectionOneCollapse_'+allProjectIndex+1*22+13"
                                                        [ngStyle]="{'background-color': (allProjectIndex | getRandomColor)}"
                                                        (click)="toggleAccordian(allProjectIndex)">
                                                        <div
                                                            class="d-flex justify-content-between align-items-center text-white">
                                                         
                                                            <span
                                                                class="d-flex justify-content-center align-items-center fs-16 twoline-sentence">
                                                                {{allprojectList?.name ||'NA'}}
                                                            </span>
                                                            <div class="pe-4">
                                                                <span class="material-icons-outlined greencircle"
                                                                    *ngIf="!allProjectsection[allProjectIndex]">
                                                                    keyboard_arrow_down
                                                                </span>
                                                                <span class="material-icons-outlined greencircle"
                                                                    *ngIf="allProjectsection[allProjectIndex]">
                                                                    keyboard_arrow_up
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div [id]="'projectSectionOneCollapse_'+allProjectIndex+1*22+13"
                                                        class="accordion-collapse collapse listhead"
                                                        [attr.aria-labelledby]="'projectHeader'+allProjectIndex+1"
                                                        data-bs-parent="#projectSection">
                                                        <div class="accordion-body p-2">
                                                            <div class="d-flex flex-column">
                                                                <div class="listContainer">

                                                                    <div class="inner-list"
                                                                        *ngIf="allprojectList?.okrList?.project?.length !== 0">
                                                                        <div class="accordion accordion-flush"
                                                                            id="sub-section-project">
                                                                            <div
                                                                                class="d-flex flex-column align-items-center">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center w-100 p-2 pb-1 pe-3 ps-3">
                                                                                    <div>
                                                                                        <img src="/assets/images/pages/Convergence/Projects.svg"
                                                                                            alt="Projects">
                                                                                        &nbsp;
                                                                                        <span
                                                                                            class="fs-16 color-blue fw-500 text-capitalize">{{staticText?.projects_list_page.projects}}</span>
                                                                                    </div>
                                                                                    <h5
                                                                                        class="mb-0 font-20 color-Port-Gore">
                                                                                        {{allprojectList?.okrList?.project?.length
                                                                                        || 0}}
                                                                                    </h5>
                                                                                </div>
                                                                                <div class="p-1"
                                                                                    *ngIf="allprojectList?.okrList?.project && allprojectList?.okrList?.project?.length > 1">
                                                                                    <mat-form-field
                                                                                        style="width: 21rem;"
                                                                                        class="example-full-width okrsearchInput"
                                                                                        appearance="outline">
                                                                                        <mat-label>
                                                                                            {{staticText?.projects_list_page.projects}}
                                                                                        </mat-label>
                                                                                        <input matInput
                                                                                            placeholder="Search {{staticText?.projects_list_page.projects}}.."
                                                                                            [(ngModel)]="searchProjects[allProjectIndex]">
                                                                                        <img class="img-fluid" matSuffix
                                                                                            src="/assets/images/balancescore/balanceheader/search.svg"
                                                                                            alt="filter">
                                                                                    </mat-form-field>
                                                                                </div>
                                                                                <div class="accordion-item pt-1 pb-1 listing-parent-card"
                                                                                    *ngFor="let project of allprojectList?.okrList?.project | filter:searchProjects[allProjectIndex]| slice:0:!projectViewAll?3:100000;index as projectIndex">
                                                                                    <div class="accordion-header  cursor-pointer p-2"
                                                                                        [id]="'sub-section-project-Header'+projectIndex+'_'+allProjectIndex"
                                                                                        data-bs-toggle="collapse"
                                                                                        aria-expanded="true"
                                                                                        [attr.data-bs-target]="'#project-Sub-Section_'+projectIndex+'_'+allProjectIndex"
                                                                                        [attr.aria-controls]="'project-Sub-Section_'+projectIndex+'_'+allProjectIndex"
                                                                                        (click)="toggleProjectAccordian(projectIndex)">
                                                                                        <div
                                                                                            class="d-flex justify-content-between align-items-center text-white">
                                                                                            <span
                                                                                                class="d-flex justify-content-center align-items-center fs-16 twoline-sentence">
                                                                                                {{project?.project_name}}
                                                                                            </span>
                                                                                            <div
                                                                                                class="d-flex flex-row align-items-center">
                                                                                                <div class="p-1">
                                                                                                    <span
                                                                                                        class="material-icons-outlined text-white cursor-pointer "
                                                                                                        (click)="navigateTo(project.id)"
                                                                                                        *ngIf="!projectId && projectId == 0 ? true : false">
                                                                                                        east
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div class="p-1"
                                                                                                    *ngIf=" project?.project_key_results">
                                                                                                    <span
                                                                                                        class="material-icons-outlined greencircle"
                                                                                                        *ngIf="!allProjectArrow[projectIndex]">
                                                                                                        keyboard_arrow_down
                                                                                                    </span>
                                                                                                    <span
                                                                                                        class="material-icons-outlined greencircle"
                                                                                                        *ngIf="allProjectArrow[projectIndex]">
                                                                                                        keyboard_arrow_up
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="accordion-collapse collapse innersub"
                                                                                        [id]="'project-Sub-Section_'+projectIndex+'_'+allProjectIndex"
                                                                                        [attr.aria-labelledby]="'sub-section-project-Header'+projectIndex+'_'+allProjectIndex"
                                                                                        data-bs-parent="#sub-section-project">
                                                                                        <div class="accordion-body"
                                                                                            *ngIf="project?.project_key_results">
                                                                                           
                                                                                            <ng-container
                                                                                                *ngFor="let ChildKeyResult of project?.project_key_results">
                                                                                                <ng-container>
                                                                                                  
                                                                                                            <div
                                                                                                                class="listing-card">
                                                                                                                <div
                                                                                                                    class="d-flexalign-items-center p-2">
                                                                                                                    <h5
                                                                                                                        class="mb-0 fs-16 fw-normal twoline-sentence">
                                                                                                                        <span
                                                                                                                            class="color-blue fs-18 fw-bold">{{ChildKeyResult?.key_title}}</span>
                                                                                                                        {{ChildKeyResult?.key_result_title}}
                                                                                                                    </h5>
                                                                                                                </div>
                                                                                                    </div>
                                                                                                </ng-container>
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <p class="d-flex justify-content-end fs-12 text-capitalize color-blue w-100 p-3 cursor-pointer"
                                                                                    (click)="projectViewAll = true"
                                                                                    *ngIf="!projectViewAll && allprojectList?.okrList?.project && allprojectList?.okrList?.project?.length > 3">
                                                                                    {{staticText?.common.view_all}}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="inner-list"
                                                                        *ngIf="allprojectList?.okrList?.idea?.length !== 0">
                                                                        <div class="accordion accordion-flush"
                                                                            id="sub-section-idea">
                                                                            <div
                                                                                class="d-flex flex-column align-items-center">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center w-100 p-2 pb-1 pe-3 ps-3">
                                                                                    <div>
                                                                                        <img src="/assets/images/pages/Convergence/Ideas.svg"
                                                                                            alt="Ideas">
                                                                                        &nbsp;
                                                                                        <span
                                                                                            class="fs-16 color-blue fw-500 text-capitalize">{{staticText?.innovation.ideas}}</span>
                                                                                    </div>
                                                                                    <h5
                                                                                        class="mb-0 font-20 color-Port-Gore">
                                                                                        {{allprojectList?.okrList?.idea?.length
                                                                                        || 0}}
                                                                                    </h5>
                                                                                </div>
                                                                                <div class="p-1"
                                                                                    *ngIf="allprojectList?.okrList?.idea && allprojectList?.okrList?.idea?.length > 1">
                                                                                    <mat-form-field
                                                                                        style="width: 21rem;"
                                                                                        class="example-full-width okrsearchInput"
                                                                                        appearance="outline">
                                                                                        <mat-label>
                                                                                            {{staticText?.innovation.ideas}}
                                                                                        </mat-label>
                                                                                        <input matInput
                                                                                            placeholder="Search Ideas.."
                                                                                            [(ngModel)]="searchIdeas[allProjectIndex]">
                                                                                        <img class="img-fluid" matSuffix
                                                                                            src="/assets/images/balancescore/balanceheader/search.svg"
                                                                                            alt="filter">
                                                                                    </mat-form-field>
                                                                                </div>
                                                                                <div class="accordion-item pt-1 pb-1 listing-parent-card"
                                                                                    *ngFor="let idea of allprojectList?.okrList?.idea | filter:searchIdeas[allProjectIndex]| slice:0:!ideasViewAll?3:100000;index as ideaIndex">
                                                                                    <div class="accordion-header  cursor-pointer p-2"
                                                                                        [id]="'sub-section-idea-Header'+ideaIndex+'_'+allProjectIndex"
                                                                                        data-bs-toggle="collapse"
                                                                                        aria-expanded="true"
                                                                                        [attr.data-bs-target]="'#idea-Sub-Section_'+ideaIndex+'_'+allProjectIndex"
                                                                                        [attr.aria-controls]="'idea-Sub-Section_'+ideaIndex+'_'+allProjectIndex"
                                                                                        (click)="toggleIdeaProjectAccordian(ideaIndex)">
                                                                                        <div
                                                                                            class="d-flex justify-content-between align-items-center text-white">
                                                                                            <span
                                                                                                class="d-flex justify-content-center align-items-center fs-16 twoline-sentence">
                                                                                                {{idea?.name}}
                                                                                            </span>
                                                                                            <div
                                                                                                class="d-flex flex-row align-items-center">
                                                                                                <div class="p-1">
                                                                                                    <span
                                                                                                        class="material-icons-outlined text-white cursor-pointer"
                                                                                                        (click) ="navigateToInnovation(idea?.id)"
                                                                                                        *ngIf="!projectId && projectId == 0 ? true : false">
                                                                                                        east
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div class="p-1"
                                                                                                    *ngIf=" idea?.key_results">
                                                                                                    <span
                                                                                                        class="material-icons-outlined greencircle"
                                                                                                        *ngIf="!allIdeadArrow[ideaIndex]">
                                                                                                        keyboard_arrow_down
                                                                                                    </span>
                                                                                                    <span
                                                                                                        class="material-icons-outlined greencircle"
                                                                                                        *ngIf="allIdeadArrow[ideaIndex]">
                                                                                                        keyboard_arrow_up
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="accordion-collapse collapse"
                                                                                        [id]="'idea-Sub-Section_'+ideaIndex+'_'+allProjectIndex"
                                                                                        [attr.aria-labelledby]="'sub-section-idea-Header'+ideaIndex+'_'+allProjectIndex"
                                                                                        data-bs-parent="#sub-section-idea">
                                                                                        <div class="accordion-body"
                                                                                            *ngIf="idea?.key_results">
                                                                                            <div class="pt-2 pb-2"
                                                                                                *ngFor="let riskLog of idea?.key_results">
                                                                                                <div
                                                                                                    class="listing-card">
                                                                                                    <div
                                                                                                        class="d-flexalign-items-center p-2">
                                                                                                        <h5
                                                                                                            class="mb-0 fs-16 fw-normal twoline-sentence">
                                                                                                            <span
                                                                                                                class="color-blue fs-18 fw-bold">{{riskLog?.key_title}}</span>
                                                                                                            {{riskLog?.key_result_title}}
                                                                                                        </h5>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <p class="d-flex justify-content-end fs-12 text-capitalize color-blue w-100 p-3 cursor-pointer"
                                                                                    (click)="ideasViewAll=true"
                                                                                    *ngIf="!ideasViewAll && allprojectList?.okrList?.idea && allprojectList?.okrList?.idea?.length > 3">
                                                                                    {{staticText?.common.view_all}}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="inner-list"
                                                                        *ngIf="allprojectList?.okrList?.opportunity?.length !== 0">
                                                                        <div class="accordion accordion-flush"
                                                                            id="sub-section-idea">
                                                                            <div
                                                                                class="d-flex flex-column align-items-center">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center w-100 p-2 pb-1 pe-3 ps-3">
                                                                                    <div>
                                                                                        <img src="/assets/images/pages/Convergence/Opportunities.svg"
                                                                                            alt="Opportunities">
                                                                                        &nbsp;
                                                                                        <span
                                                                                            class="fs-16 color-blue fw-500 text-capitalize">{{staticText?.opportunity.opportunities}}</span>
                                                                                    </div>
                                                                                    <h5
                                                                                        class="mb-0 font-20 color-Port-Gore">
                                                                                        {{allprojectList?.okrList?.opportunity?.length
                                                                                        || 0}}
                                                                                    </h5>
                                                                                </div>
                                                                                <div class="p-1"
                                                                                    *ngIf="allprojectList?.okrList?.opportunity && allprojectList?.okrList?.opportunity?.length > 1">
                                                                                    <mat-form-field
                                                                                        style="width: 21rem;"
                                                                                        class="example-full-width okrsearchInput"
                                                                                        appearance="outline">
                                                                                        <mat-label>
                                                                                            {{staticText?.opportunity.opportunities}}
                                                                                        </mat-label>
                                                                                        <input matInput
                                                                                            placeholder="Search {{staticText?.opportunity.opportunities}}.."
                                                                                            [(ngModel)]="searchOpportunities[allProjectIndex]">
                                                                                        <img class="img-fluid" matSuffix
                                                                                            src="/assets/images/balancescore/balanceheader/search.svg"
                                                                                            alt="filter">
                                                                                    </mat-form-field>
                                                                                    <div class="accordion-item pt-1 pb-1 listing-parent-card"
                                                                                        *ngFor="let opportunity of allprojectList?.okrList?.opportunity | filter:searchOpportunities[allProjectIndex]| slice:0:!opportunityViewAll?3:100000;;index as opportunityIndex">
                                                                                        <div class="accordion-header  cursor-pointer p-2"
                                                                                            [id]="'sub-section-opportunity-Header'+opportunityIndex+'_'+allProjectIndex"
                                                                                            data-bs-toggle="collapse"
                                                                                            aria-expanded="true"
                                                                                            [attr.data-bs-target]="'#opportunity-Sub-Section_'+opportunityIndex+'_'+allProjectIndex"
                                                                                            [attr.aria-controls]="'opportunity-Sub-Section_'+opportunityIndex+'_'+allProjectIndex"
                                                                                            (click)="toggleOppAccordian(opportunityIndex)">
                                                                                            <div
                                                                                                class="d-flex justify-content-between align-items-center text-white">
                                                                                                <span
                                                                                                    class="d-flex justify-content-center align-items-center fs-16 twoline-sentence">
                                                                                                    {{opportunity?.opportunity_name}}
                                                                                                </span>
                                                                                                <div
                                                                                                    class="d-flex flex-row align-items-center">
                                                                                                    <div class="p-1">
                                                                                                        <span
                                                                                                            class="material-icons-outlined text-white cursor-pointer"
                                                                                                            (click)="navigateToPreview(opportunities?.id)" 
                                                                                                            *ngIf="!projectId && projectId == 0 ? true : false">
                                                                                                            east
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div class="p-1"
                                                                                                        *ngIf="opportunity?.opportunity_key_results?.length">
                                                                                                        <span
                                                                                                            class="material-icons-outlined greencircle"
                                                                                                            *ngIf="!allOpportunityArrow[opportunityIndex]">
                                                                                                            keyboard_arrow_down
                                                                                                        </span>
                                                                                                        <span
                                                                                                            class="material-icons-outlined greencircle"
                                                                                                            *ngIf="allOpportunityArrow[opportunityIndex]">
                                                                                                            keyboard_arrow_up
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="accordion-collapse collapse"
                                                                                            [id]="'opportunity-Sub-Section_'+opportunityIndex+'_'+allProjectIndex"
                                                                                            [attr.aria-labelledby]="'sub-section-opportunity-Header'+opportunityIndex+'_'+allProjectIndex"
                                                                                            data-bs-parent="#sub-section-opportunity">
                                                                                            <div class="accordion-body"
                                                                                                *ngIf="opportunity?.opportunity_key_results">
                                                                                                <ng-container
                                                                                                    *ngFor="let ChildKeyResult of opportunity?.opportunity_key_results">
                                                                                                    <ng-container>
                                                                                                       
                                                                                                                <div
                                                                                                                    class="listing-card">
                                                                                                                    <div
                                                                                                                        class="d-flexalign-items-center p-2">
                                                                                                                        <h5
                                                                                                                            class="mb-0 fs-16 fw-normal twoline-sentence">
                                                                                                                            <span
                                                                                                                                class="color-blue fs-18 fw-bold">{{ChildKeyResult?.key_title}}</span>
                                                                                                                            {{ChildKeyResult?.key_result_title}}
                                                                                                                        </h5>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                    </ng-container>
                                                                                                </ng-container>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p class="d-flex justify-content-end fs-12 text-capitalize color-blue w-100 p-1 mb-0 cursor-pointer"
                                                                                        (click)="opportunityViewAll=true;"
                                                                                        *ngIf="!opportunityViewAll  && allprojectList?.okrList?.opportunity && allprojectList?.okrList?.opportunity?.length > 3">
                                                                                        {{staticText?.common.view_all}}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-center no-Records"
                                                                        *ngIf="allprojectList?.okrList?.project?.length === 0 && allprojectList?.okrList?.idea?.length===0 && allprojectList?.okrList?.opportunity?.length === 0 && !selectedProject">
                                                                        <p class="my-2">
                                                                            {{staticText.common.no_records_found}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="allOkrHeatMapProjects?.length == 0">
                                                <div class="col-md-12 mx-auto">
                                                  <img src="./assets/images/no-record.png" class="no-records" height="100%" width="100%">
                                                </div>
                                                <div class="col-md-8 mx-auto">
                                                  <p class="text-center fw-bold text-muted">
                                                    {{staticText.common.no_records_found}}
                                                  </p>
                                                </div>
                                              </div>
                                        </div>
                                        <div class="p-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="projectlist" *ngIf="!showAllProjects">
                                    <p class="text-right mb-1 cursor-pointer"
                                        (click)="showAllProjects = !showAllProjects;">
                                        <b>{{staticText?.filter.clear_filter}}</b>
                                    </p>
                                    <div class="p-1 scroll-All-container">
                                        <div class="accordion accordion-flush" id="projectSection">
                                            <div class="pt-2 pb-2">
                                                <div class="accordion-item sectionOne">
                                                    <div class="accordion-header  cursor-pointer p-2 projectTitleCard"
                                                        [id]="'projectHeader'+1" data-bs-toggle="collapse"
                                                        aria-expanded="true"
                                                        [attr.data-bs-target]="'#projectSectionOneCollapse_'+1*22+13"
                                                        [attr.aria-controls]="'projectSectionOneCollapse_'+1*22+13"
                                                        [ngStyle]="{'background-color': (0 | getRandomColor)}"
                                                        (click)="toggleAccordian(0)">
                                                        <div
                                                            class="d-flex justify-content-between align-items-center text-white">
                                                            <span
                                                                class="d-flex justify-content-center align-items-center fs-16 twoline-sentence">
                                                                {{selectedProject?.name ||'NA'}}
                                                            </span>
                                                            <div class="pe-4">
                                                                <span class="material-icons-outlined greencircle"
                                                                    *ngIf="!allProjectsection[0]">
                                                                    keyboard_arrow_down
                                                                </span>
                                                                <span class="material-icons-outlined greencircle"
                                                                    *ngIf="allProjectsection[0]">
                                                                    keyboard_arrow_up
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div [id]="'projectSectionOneCollapse_'++1*22+13"
                                                        class="accordion-collapse collapse listhead"
                                                        [attr.aria-labelledby]="'projectHeader'+1"
                                                        data-bs-parent="#projectSection">
                                                        <div class="accordion-body p-2">
                                                            <div class="d-flex flex-column">
                                                                <div class="listContainer">
                                                                    <div class="inner-list">
                                                                        <div class="accordion accordion-flush"
                                                                            id="sub-section-project">
                                                                            <div
                                                                                class="d-flex flex-column align-items-center">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center w-100 p-2 pb-1 pe-3 ps-3">
                                                                                    <div>
                                                                                        <img src="/assets/images/pages/Convergence/Projects.svg"
                                                                                            alt="Projects">
                                                                                        &nbsp;
                                                                                        <span
                                                                                            class="fs-16 color-blue fw-500 text-capitalize">{{staticText?.projects_list_page.projects}}</span>
                                                                                    </div>
                                                                                    <h5
                                                                                        class="mb-0 font-20 color-Port-Gore">
                                                                                        {{projectList?.length
                                                                                        || 0}}
                                                                                    </h5>
                                                                                </div>
                                                                                <div class="p-1"
                                                                                    *ngIf="projectList && projectList?.length > 1">
                                                                                    <mat-form-field
                                                                                        class="example-full-width okrsearchInput"
                                                                                        style="width: 21rem;"
                                                                                        appearance="outline">
                                                                                        <mat-label>
                                                                                            {{staticText?.projects_list_page.projects}}
                                                                                        </mat-label>
                                                                                        <input matInput
                                                                                            placeholder="Search {{staticText?.projects_list_page.projects}}..."
                                                                                            [(ngModel)]="selectedProjectsection">
                                                                                        <img class="img-fluid" matSuffix
                                                                                            src="/assets/images/balancescore/balanceheader/search.svg"
                                                                                            alt="filter">
                                                                                    </mat-form-field>
                                                                                </div>
                                                                                <div class="accordion-item pt-1 pb-1 listing-parent-card"
                                                                                    *ngFor="let project of projectList | filter:selectedProjectsection | slice:0:!projectViewAll?3:100000;index as projectIndex">
                                                                                    <div class="accordion-header  cursor-pointer p-2"
                                                                                        [id]="'sub-section-project-Header'+projectIndex"
                                                                                        data-bs-toggle="collapse"
                                                                                        aria-expanded="true"
                                                                                        [attr.data-bs-target]="'#project-Sub-Section_'+projectIndex"
                                                                                        [attr.aria-controls]="'project-Sub-Section_'+projectIndex"
                                                                                        (click)="toggleProjectAccordian(projectIndex)">
                                                                                        <div
                                                                                            class="d-flex justify-content-between align-items-center text-white">
                                                                                            <span
                                                                                                class="d-flex justify-content-center align-items-center fs-16 twoline-sentence">
                                                                                                {{project?.project_name}}
                                                                                            </span>
                                                                                            <div
                                                                                                class="d-flex flex-row align-items-center">
                                                                                                <div class="p-1">
                                                                                                    <span
                                                                                                        class="material-icons-outlined color-blue text-white cursor-pointer"
                                                                                                       (click)="navigateToOKRlog(project.id)"
                                                                                                        *ngIf="!projectId && projectId == 0 ? true : false">
                                                                                                        east
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div class="p-1"
                                                                                                    *ngIf="project?.project_key_results">
                                                                                                    <span
                                                                                                        class="material-icons-outlined greencircle"
                                                                                                        *ngIf="!allProjectArrow[projectIndex]">
                                                                                                        keyboard_arrow_down
                                                                                                    </span>
                                                                                                    <span
                                                                                                        class="material-icons-outlined greencircle"
                                                                                                        *ngIf="allProjectArrow[projectIndex]">
                                                                                                        keyboard_arrow_up
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="accordion-collapse collapse innersub"
                                                                                        [id]="'project-Sub-Section_'+projectIndex"
                                                                                        [attr.aria-labelledby]="'sub-section-project-Header'+projectIndex"
                                                                                        data-bs-parent="#sub-section-project">
                                                                                        <div class="accordion-body"
                                                                                            *ngIf="project?.project_key_results">
                                                                                            <ng-container
                                                                                                *ngFor="let ChildKeyResult of project?.project_key_results">
                                                                                                <ng-container>
                                                                                                            <div
                                                                                                                class="listing-card">
                                                                                                                <div
                                                                                                                    class="d-flexalign-items-center p-2">
                                                                                                                    <h5
                                                                                                                        class="mb-0 fs-16 fw-normal twoline-sentence">
                                                                                                                        <span
                                                                                                                            class="color-blue fs-18 fw-bold">{{ChildKeyResult?.key_title}}</span>
                                                                                                                        {{ChildKeyResult?.key_result_title}}
                                                                                                                    </h5>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                </ng-container>
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <p class="d-flex justify-content-end fs-12 text-capitalize color-blue w-100 p-3 cursor-pointer"
                                                                                    (click)="projectViewAll = true"
                                                                                    *ngIf="!projectViewAll && projectList?.length >3">
                                                                                    {{staticText?.common.view_all}}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="inner-list"
                                                                        *ngIf="ideaList?.length !== 0">
                                                                        <div class="accordion accordion-flush"
                                                                            id="sub-section-idea">
                                                                            <div
                                                                                class="d-flex flex-column align-items-center">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center w-100 p-2 pb-1 pe-3 ps-3">
                                                                                    <div>
                                                                                        <img src="/assets/images/pages/Convergence/Ideas.svg"
                                                                                            alt="Ideas">
                                                                                        &nbsp;
                                                                                        <span
                                                                                            class="fs-16 color-blue fw-500 text-capitalize">{{staticText?.innovation.ideas}}</span>
                                                                                    </div>
                                                                                    <h5
                                                                                        class="mb-0 font-20 color-Port-Gore">
                                                                                        {{ideaList?.length
                                                                                        || 0}}
                                                                                    </h5>
                                                                                </div>
                                                                                <div class="p-1"
                                                                                    *ngIf="ideaList && ideaList?.length > 1">
                                                                                    <mat-form-field
                                                                                        class="example-full-width okrsearchInput"
                                                                                        style="width: 21rem;"
                                                                                        appearance="outline">
                                                                                        <mat-label>
                                                                                            {{staticText?.innovation.ideas}}
                                                                                        </mat-label>
                                                                                        <input matInput
                                                                                            placeholder="Search Ideas..."
                                                                                            [(ngModel)]="selectedSearchIdeas">
                                                                                        <img class="img-fluid" matSuffix
                                                                                            src="/assets/images/balancescore/balanceheader/search.svg"
                                                                                            alt="filter">
                                                                                    </mat-form-field>
                                                                                </div>
                                                                                <div class="accordion-item pt-2 pb-2 listing-parent-card"
                                                                                    *ngFor="let idea of ideaList | filter:selectedSearchIdeas | slice:0:!ideasViewAll?3:100000;index as ideaIndex">
                                                                                    <div class="accordion-header  cursor-pointer p-2"
                                                                                        [id]="'sub-section-idea-Header'+ideaIndex"
                                                                                        data-bs-toggle="collapse"
                                                                                        aria-expanded="true"
                                                                                        [attr.data-bs-target]="'#idea-Sub-Section_'+ideaIndex"
                                                                                        [attr.aria-controls]="'idea-Sub-Section_'+ideaIndex"
                                                                                        (click)="toggleIdeaProjectAccordian(ideaIndex)">
                                                                                        <div
                                                                                            class="d-flex justify-content-between align-items-center text-white">
                                                                                            <span
                                                                                                class="d-flex justify-content-center align-items-center fs-16 twoline-sentence">
                                                                                                {{idea?.name}}
                                                                                            </span>
                                                                                            <div
                                                                                                class="d-flex flex-row align-items-center">
                                                                                                <div class="p-1">
                                                                                                    <span
                                                                                                        class="material-icons-outlined text-white color-blue cursor-pointer"
                                                                                                        (click) ="navigateToInnovation(idea?.id)"
                                                                                                        *ngIf="!projectId && projectId == 0 ? true : false">
                                                                                                        east
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div class="p-1"
                                                                                                    *ngIf="idea?.key_results">
                                                                                                    <span
                                                                                                        class="material-icons-outlined greencircle"
                                                                                                        *ngIf="!allIdeadArrow[ideaIndex]">
                                                                                                        keyboard_arrow_down
                                                                                                    </span>
                                                                                                    <span
                                                                                                        class="material-icons-outlined greencircle"
                                                                                                        *ngIf="allIdeadArrow[ideaIndex]">
                                                                                                        keyboard_arrow_up
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="accordion-collapse collapse"
                                                                                        [id]="'idea-Sub-Section_'+ideaIndex"
                                                                                        [attr.aria-labelledby]="'sub-section-idea-Header'+ideaIndex"
                                                                                        data-bs-parent="#sub-section-idea">
                                                                                        <div class="accordion-body"
                                                                                            *ngIf="idea?.key_results">
                                                                                            <div class="pt-2 pb-2"
                                                                                                *ngFor="let riskLog of idea?.key_results">
                                                                                                <div
                                                                                                    class="listing-card">
                                                                                                    <div
                                                                                                        class="d-flexalign-items-center p-2">
                                                                                                        <h5
                                                                                                            class="mb-0 fs-16 fw-normal twoline-sentence">
                                                                                                            <span
                                                                                                                class="color-blue fs-18 fw-bold">{{riskLog?.key_title}}</span>
                                                                                                            {{ChildKeyResult?.key_result_title}}
                                                                                                        </h5>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <p class="d-flex justify-content-end fs-12 text-capitalize color-blue w-100 p-3 cursor-pointer"
                                                                                    (click)="ideasViewAll=true"
                                                                                    *ngIf="!ideasViewAll && ideaList?.length > 3">
                                                                                    {{staticText?.common.view_all}}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div class="inner-list"
                                                                        *ngIf="opportunityList?.length !== 0">
                                                                        <div class="accordion accordion-flush"
                                                                            id="sub-section-idea">
                                                                            <div
                                                                                class="d-flex flex-column align-items-center">
                                                                                <div
                                                                                    class="d-flex justify-content-between align-items-center w-100 p-2 pb-1 pe-3 ps-3">
                                                                                    <div>
                                                                                        <img src="/assets/images/pages/Convergence/Opportunities.svg"
                                                                                            alt="Opportunities">
                                                                                        &nbsp;
                                                                                        <span
                                                                                            class="fs-16 color-blue fw-500 text-capitalize">{{staticText?.opportunity.opportunities}}</span>
                                                                                    </div>
                                                                                    <h5
                                                                                        class="mb-0 font-20 color-Port-Gore">
                                                                                        {{opportunityList?.length
                                                                                        || 0}}
                                                                                    </h5>
                                                                                </div>
                                                                                <div class="p-1"
                                                                                    *ngIf="opportunityList && opportunityList?.length > 1">
                                                                                    <mat-form-field
                                                                                        class="example-full-width okrsearchInput"
                                                                                        style="width: 21rem;"
                                                                                        appearance="outline">
                                                                                        <mat-label>
                                                                                            {{staticText?.opportunity.opportunities}}
                                                                                        </mat-label>
                                                                                        <input matInput
                                                                                            placeholder="Search Opportunities..."
                                                                                            [(ngModel)]="selectedSearchOpportunities">
                                                                                        <img class="img-fluid" matSuffix
                                                                                            src="/assets/images/balancescore/balanceheader/search.svg"
                                                                                            alt="filter">
                                                                                    </mat-form-field>
                                                                                    <div class="accordion-item pt-2 pb-2 listing-parent-card"
                                                                                        *ngFor="let opportunity of opportunityList | filter:selectedSearchOpportunities | slice:0:!opportunityViewAll?3:100000;index as opportunityIndex">
                                                                                        <div class="accordion-header  cursor-pointer p-2"
                                                                                            [id]="'sub-section-opportunity-Header'+opportunityIndex"
                                                                                            data-bs-toggle="collapse"
                                                                                            aria-expanded="true"
                                                                                            [attr.data-bs-target]="'#opportunity-Sub-Section_'+opportunityIndex"
                                                                                            [attr.aria-controls]="'opportunity-Sub-Section_'+opportunityIndex"
                                                                                            (click)="toggleOppAccordian(opportunityIndex)">
                                                                                            <div
                                                                                                class="d-flex justify-content-between align-items-center text-white">
                                                                                                <span
                                                                                                    class="d-flex justify-content-center align-items-center fs-16 twoline-sentence">
                                                                                                    {{opportunity?.opportunity_name}}
                                                                                                </span>
                                                                                                <div
                                                                                                    class="d-flex flex-row align-items-center">
                                                                                                    <div class="p-1">
                                                                                                        <span
                                                                                                            class="material-icons-outlined text-white cursor-pointer"
                                                                                                            (click)="navigateToPreview(opportunity?.id)" 
                                                                                                            *ngIf="!projectId && projectId == 0 ? true : false">
                                                                                                            east
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div class="p-1"
                                                                                                        *ngIf="opportunity?.opportunity_key_results?.length">
                                                                                                        <span
                                                                                                            class="material-icons-outlined greencircle"
                                                                                                            *ngIf="!allOpportunityArrow[opportunityIndex]">
                                                                                                            keyboard_arrow_down
                                                                                                        </span>
                                                                                                        <span
                                                                                                            class="material-icons-outlined greencircle"
                                                                                                            *ngIf="allOpportunityArrow[opportunityIndex]">
                                                                                                            keyboard_arrow_up
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="accordion-collapse collapse"
                                                                                            [id]="'opportunity-Sub-Section_'+opportunityIndex"
                                                                                            [attr.aria-labelledby]="'sub-section-opportunity-Header'+opportunityIndex"
                                                                                            data-bs-parent="#sub-section-opportunity">
                                                                                            <div class="accordion-body"
                                                                                                *ngIf="opportunity?.opportunity_key_results">
                                                                                                <ng-container
                                                                                                    *ngFor="let ChildKeyResult of opportunity?.opportunity_key_results">
                                                                                                    <ng-container>
                                                                                                                <div
                                                                                                                    class="listing-card">
                                                                                                                    <div
                                                                                                                        class="d-flexalign-items-center p-2">
                                                                                                                        <h5
                                                                                                                            class="mb-0 fs-16 fw-normal twoline-sentence">
                                                                                                                            <span
                                                                                                                                class="color-blue fs-18 fw-bold">{{ChildKeyResult?.key_title}}</span>
                                                                                                                            {{ChildKeyResult?.key_result_title}}
                                                                                                                        </h5>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                    </ng-container>
                                                                                                </ng-container>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <p class="d-flex justify-content-end fs-12 text-capitalize color-blue w-100 p-1 mb-0 cursor-pointer"
                                                                                        (click)="opportunityViewAll=true;"
                                                                                        *ngIf="!opportunityViewAll  && opportunityList?.length > 3">
                                                                                        {{staticText?.common.view_all}}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-center no-Records"
                                                                        *ngIf="projectList.length === 0 && ideaList?.length===0 && opportunityList?.length === 0 && !selectedProject">
                                                                        <p class="my-2">
                                                                            {{staticText.common.no_records_found}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-1">
                                            <button class="OkrLog text-white float-right w-100 fw-normal"
                                                mat-raised-button  (click)="navigateToOKRlog(projectId)"
                                                *ngIf="projectId && projectId !==0 ? true : false">
                                                {{staticText?.okr_heatmap.goto_log}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<footer class="okrconvergence-Footer">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="text-muted">
                    <p align="center">Copyright © {{currentYear}} {{staticText.footer.text}}</p>
                </div>
            </div>
        </div>
    </div>
</footer> -->
