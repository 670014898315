import { T } from '@angular/cdk/keycodes';
import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { OkrConvergenceService } from 'src/app/shared/services/okr-convergence.service';
import { PowerBiService } from 'src/app/shared/services/power-bi.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-insight',
  templateUrl: './insight.component.html',
  styleUrls: ['./insight.component.scss']
})
export class InsightComponent implements OnInit {
  @Input() OKRData: any;
  reportClass = 'report-container';
  reportModel:IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  }; 
  insightModel = this.reportModel;
  mosList:any = [];
  selectedTab:boolean = true
  OKRList:any = [];
  ytd:any = '';
  ltm:any = '';
  setYear:any = '';
  setMonth:any = '';
  filterObj: any;
  filterField: any;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private PowerBiService : PowerBiService,
    private spinner: NgxSpinnerService,
    private okrConvergenceService: OkrConvergenceService,
    private filterService: FilterService,
   
  ) { }

  ngOnInit(): void {
    this.getMos();
    this.getPowerBIReportDetails();
    this.filterService.setValue('');
    this.filterService.filterSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if(this.filterObj[element]?.array?.length > 0){
          awl = true;
          return;
        }
      });
      if(Object.keys(this.filterObj).length > 0){
        this.getData();
      }
    })
  }

  getMaster(){
    this.OKRList = [];
    this.spinner.show();
    this.OKRData.forEach((obj: any) => {
        this.OKRList.push(obj);
      });
    
      this.OKRList.forEach((obj: any) => {
        obj.end_date = obj.end_date ? new Date(obj.end_date) : "";

        obj.key_results.forEach((kr: any) => {
          kr.end_date = kr.end_date ? new Date(kr.end_date) : "";

          kr.goals.forEach((goal: any) => {
            goal.end_date = goal.end_date ? new Date(goal.end_date) : "";
          });
        });
      });
      
      this.spinner.hide();
    
  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "okr"
    }
  }

  getMos(){
    this.okrConvergenceService.getMosMaster().subscribe((res: any) => {
      this.mosList = res;
      this.getMaster();
   
    })
  }

  getMosInfo(item: any){
    return this.mosList.filter((e: any) => e.id === item)[0]
  }

  changeTab(){
    if(this.selectedTab == true){
      return this.selectedTab = false;
    }
    return this.selectedTab = true;
  }

  async getPowerBIReportDetails(){
    await this.PowerBiService.getPowerBIReportDetails('okr_insight').subscribe((res:any)=>{
      let report = res[0];
      this.loadPowerBIReports(report)
    })
  }
  
  loadPowerBIReports(report:any){
    let basicFilter ='';
    this.PowerBiService.getTokenForBI().subscribe((res: any) => {
          this.PowerBiService.getBIEmbedURL(res.access_token,report?.report_id).subscribe((reportData: any) => {
            this.PowerBiService.getBIEmbedToken(res.access_token,report?.report_id,report?.group).subscribe((tokenData: any) => {
              this.insightModel = this.loadPowerBIReportGraph(reportData,tokenData,basicFilter,report?.report_name);
            })
        });
    });
   }
  
   loadPowerBIReportGraph(reportData:any,tokenData:any,basicFilter:any,pageName:any){
    return {type: 'report',
     id: reportData.id,
     embedUrl: reportData.embedUrl,
     accessToken:tokenData.token,
     tokenType: models.TokenType.Embed,
     pageName: pageName,
     // filters: [basicFilter],
     settings: {
         panes: {
             filters: {
                 expanded: false,
                 visible: false
             },
             pageNavigation: {
               visible: false
             },
         },
         zoomLevel :0.6,
         background: models.BackgroundType.Transparent,
       }
     }
   }
   setYTD(){
    if(this.ytd == false){
      this.ytd = true;
      this.ltm = '';
      this.setYear = '';
      this.setMonth = '';
      this.getData();
      return;
    } 
    this.ytd = '';
    this.getData();
  }

   setLTM(){
    if(this.ltm == false){
      this.ltm = true;
      this.ytd = '';
      this.setYear = '';
      this.setMonth = '';
      this.getData();
      return;
    } 
    this.ltm = '';
    this.getData();
   }

   yearChange(){
    this.ltm = '';
    this.ytd = '';
    this.getData();
   }
   monthChange(){
    this.ltm = '';
    this.ytd = '';
    this.getData();
   }

   getData(){
    let queryparam = `ytd=${this.ytd}&ltm=${this.ltm}&year=${this.setYear}&month=${this.setMonth}`;
    if (this.filterObj) {
      Object.keys(this.filterObj).forEach((key: any) => {
        this.filterObj[key].array.forEach((element: any) => {
          switch (key) {
            case "okrobj":
              queryparam = queryparam + "&obj_id=" + element.id;
              break;
            case "okrkr":
              queryparam = queryparam + "&key_id=" + element.id;
              break;
            case "okrgoal":
              queryparam = queryparam + "&goal_id=" + element.id;
              break;
            case "project":
              queryparam = queryparam + "&project_id=" + element.id;
              break;
            case "opportunity":
              queryparam = queryparam + "&opp_id=" + element.id;
              break;
            default:
              break;
          }
        });
      });
    }
    this.spinner.show()
    this.okrConvergenceService.getGoalMaster(queryparam).subscribe((res: any) => {
      this.OKRData = res.okr_hierarchy; 
      this.OKRData.forEach((obj: any) => {
        this.OKRList.push(obj);
      });
      this.OKRList.forEach((obj: any) => {
        obj.end_date = obj.end_date ? new Date(obj.end_date) : "";

        obj.key_results.forEach((kr: any) => {
          kr.end_date = kr.end_date ? new Date(kr.end_date) : "";

          kr.goals.forEach((goal: any) => {
            goal.end_date = goal.end_date ? new Date(goal.end_date) : "";
          });
        });
      });
      this.getMaster();
      this.spinner.hide()
    });
   }

   ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
