import { ViewEncapsulation } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Chart } from "angular-highcharts";
import * as Highcharts from "highcharts";
import highchartsTreemap from "highcharts/modules/treemap";
import { isArray } from "lodash";
import { NgxPermissionsService } from "ngx-permissions";
import { NgxSpinnerService } from "ngx-spinner";
import { OkrConvergenceService } from "src/app/shared/services/okr-convergence.service";
import { ProgramService } from "src/app/shared/services/program.service";
import { MessageService } from "src/app/shared/services/message.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "src/app/shared/components/modal/modal.component";
import { DatePipe, Location } from "@angular/common";
import { FilterService } from "src/app/shared/services/filter.service";

highchartsTreemap(Highcharts);
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardComponent implements OnInit {
  currentYear: number | any;
  treeMapChart1: any;
  okrHeatMapData: any[] = [];
  CarouselFilterList: any[] = [];
  projectList: any[] = [];
  ideaList: any[] = [];
  opportunityList: any[] = [];
  projectViewAll: boolean = false;
  ideasViewAll: boolean = false;
  opportunityViewAll: boolean = false;
  initiativeData: any;
  initativeId: any;
  subPortId: any;
  portFolioId: any;
  subPortfilteredOptions: any[] = [];
  ProjectCount: any;
  selectedProject: any;
  projectListData: any;
  projectId: any = "";
  staticText: any = (textConfiguration as any).default;
  allOkrHeatMapProjects: any[] = [];
  showAllProjects: boolean = true;
  searchProjects: any = [];
  btoa: any = btoa;
  pagetype = "OKR-dasboard";
  params: any;

  //--------All list variable-------

  allProjectsection: any = [];
  searchOpportunities: any = [];
  searchIdeas: any = [];

  //--------Selected list variable-----

  selectedProjectsection!: string;
  selectedSearchIdeas!: string;
  selectedSearchOpportunities!: string;

  allProjectArrow: any = [];
  allIdeadArrow: any = [];
  allOpportunityArrow: any = [];
  pagename: string = "OKR Insights";
  display_header: Boolean = false;
  pagename1: string = "OKR Heatmap";
  oppoDataList: any;
  projectDataList: any;

  portFolioList: any;
  applications: any;
  loading: boolean = false;
  context: any;

  selected: any;
  selectedTab: any = { name: "OKR Heatmap", status: "active", tab: 1 };

  accordionItemsLeft = [
    { name: "OKR Heatmap", status: "active", tab: 1 },
    { name: "Insights", status: "inactive", tab: 2 },
  ];

  selectedGoalName: any;
  filterObj: any;
  filterField: any;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private okrConvergenceService: OkrConvergenceService,
    private ProgramService: ProgramService,
    private spinner: NgxSpinnerService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private messageService: MessageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private filterService: FilterService
  ) {
    this.route.queryParams.subscribe(async (params) => {
      this.params = params;
      this.location.replaceState(this.location.path().split("?")[0], "");
    });

    this.context = {
      componentParent: this,
    };
  }

  ngOnInit(): void {
    this.permissionsService.loadPermissions(
      JSON.parse(localStorage.getItem("permission") || "{}").permissions
    );
    this.currentYear = new Date().getFullYear();
    this.messageService.setGnews("OKR Heatmap");
    let firstTime: boolean = true;
    this.filterService.setValue("");
    this.filterService.filterSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        let awl = false;
        this.filterObj = res;
        Object.keys(this.filterObj).forEach((element: any) => {
          if (this.filterObj[element]?.array?.length > 0) {
            awl = true;
            return;
          }
        });
        if (firstTime && awl == false) {
          this.getOkrHeatMap();
          firstTime = false;
        }
        if (awl) {
          this.getOkrHeatMap();
          firstTime = true;
        }
      });
    this.filterService.filterFlag.subscribe((ele: any) => {
      this.filterField = ele.field;
    });

    // this.filtersFunctions();
  }

  clickOnTab(tab: any) {
    this.accordionItemsLeft = this.accordionItemsLeft.map((obj: any) => {
      if (obj.tab == tab) {
        this.selectedTab = obj;
        return { ...obj, status: "active" };
      } else {
        return { ...obj, status: "inactive" };
      }
    });
  }
  getOkrHeatMap() {
    this.spinner.show();
    let queryparam: string = "";
    if (this.filterObj) {
      Object.keys(this.filterObj).forEach((key: any) => {
        this.filterObj[key].array.forEach((element: any) => {
          switch (key) {
            case "okrobj":
              queryparam = queryparam + "&obj_id=" + element.id;
              break;
            case "okrkr":
              queryparam = queryparam + "&key_id=" + element.id;
              break;
            case "okrgoal":
              queryparam = queryparam + "&goal_id=" + element.id;
              break;
            case "project":
              queryparam = queryparam + "&project_id=" + element.id;
              break;
            case "opportunity":
              queryparam = queryparam + "&opp_id=" + element.id;
              break;
            default:
              break;
          }
        });
      });
    }
    console.log(queryparam);
    this.okrConvergenceService.getGoalMaster(queryparam).subscribe(
      (getHeatMap: any) => {
        this.spinner.hide();
        this.okrHeatMapData = getHeatMap.okr_hierarchy;
        let nb = false;

        let len = 0;
        this.okrHeatMapData.forEach((obj: any) => {
          obj.key_results.forEach((kr: any) => {
            kr.goals.length > len ? (len = kr.goals.length) : len;
          });
        });
        this.okrHeatMapData.forEach((obj: any) => {
          obj.key_results.forEach((kr: any) => {
            if (kr.goals.length < len) {
              for (var array = [], i = kr.goals.length; i < len; i++) {
                kr.goals.push({});
              }
            }
            kr.goals.forEach((goal: any) => {
              if (!nb) {
                this.showGoalInfo(goal);
                nb = true;
              }
            });
          });
        });
        console.log(this.okrHeatMapData);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getTrucatedName(subdomain: string, name: string) {
    if (!name) {
      return "NA";
    } else {
      if (name.length > subdomain.length) {
        return (
          name.substring(
            0,
            subdomain.length - 1 > 10 ? subdomain.length - 15 : 10
          ) + "..."
        );
      } else {
        return name;
      }
    }
  }

  getFixedName(name: string) {
    if (!name) {
      return "NA";
    } else {
      if (name.length > 110) {
        return name.substring(0, 107) + "...";
      } else {
        console.log(name.concat(" ".repeat(110 - name.length)).length);
        return name.concat(" ".repeat(110 - name.length));
      }
    }
  }

  showGoalInfo(goal: any) {
    this.selected = goal.id;
    this.selectedGoalName = goal.goal_name;
    delete this.oppoDataList;
    delete this.projectDataList;
    this.loading = true;

    let queryParam = `goal=${goal.id}`;
    this.okrConvergenceService.getGoalOpp(queryParam).subscribe(
      (getoppo: any) => {
        getoppo.portfolio.forEach((element: any) => {
          element.type = "opportunity";
          element.color = this.getGMapRandomColor();
        });
        this.oppoDataList = getoppo.portfolio;
        this.loading = false;
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
      }
    );

    queryParam = `goal=${goal.id}`;
    this.okrConvergenceService.getGoalProject(queryParam).subscribe(
      (getproj: any) => {
        getproj.projects.forEach((element: any) => {
          element.type = "project";
          element.color = this.getGMapRandomColor();
        });
        this.projectDataList = getproj.projects;
        this.loading = false;
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
      }
    );

    queryParam = `goal=${goal.id}`;
    this.okrConvergenceService.getGoalApplication(queryParam).subscribe(
      (getApplication: any) => {
        getApplication.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
        });

        this.applications = getApplication;
        this.loading = false;
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
      }
    );
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 30%, 75%, 100%)";
  }

  collapse(item: any) {
    let sData: any = [];
    if (item.type == "opportunity") {
      sData = this.oppoDataList;
    } else if (item.type == "project") {
      sData = this.projectDataList;
    }

    if (item.level == "portfolio") {
      sData.forEach((element: any) => {
        if (element.id != item.id) element.expanded = false;
      });
    } else if (item.level == "sub_portfolio") {
      let portfolioIndex = sData.findIndex((e: any) => e.id == item.parent.id);

      if (sData[portfolioIndex].children.length > 0) {
        sData[portfolioIndex].children.forEach((element: any) => {
          if (element.id != item.id) element.expanded = false;
        });
      }
    } else if (item.level == "program") {
      let portfolioIndex = sData.findIndex(
        (e: any) => e.id == item.parent.parent.id
      );
      let subportfolioIndex = sData[portfolioIndex].children.findIndex(
        (e: any) => e.id == item.parent.id
      );

      if (
        sData[portfolioIndex].children[subportfolioIndex].children.length > 0
      ) {
        sData[portfolioIndex].children[subportfolioIndex].children.forEach(
          (element: any) => {
            if (element.id != item.id) element.expanded = false;
          }
        );
      }
    }

    if (item.type == "opportunity") {
      this.oppoDataList = sData;
    } else if (item.type == "project") {
      this.projectDataList = sData;
    }
  }

  async getLevel(item: any = null) {
    if (item.children) {
      return;
    }
    let response: any;

    if (item.type == "opportunity") {
      if (item.level == "portfolio") {
        let queryParam = `portfolio=${item.id}&goal=${this.selected}`;

        response = await this.okrConvergenceService
          .getGoalOpp(queryParam)
          .subscribe((res: any) => {
            if (res.sub_portfolio.length > 0) {
              res.sub_portfolio.forEach((element: any) => {
                element.parent = item;
                element.type = "opportunity";
              });
            }
            this.oppoDataList[this.oppoDataList.indexOf(item)].children =
              res.sub_portfolio;
            return res;
          });
      } else if (item.level == "sub_portfolio") {
        let queryParam = `sub_portfolio=${item.id}&goal=${this.selected}`;

        response = await this.okrConvergenceService
          .getGoalOpp(queryParam)
          .subscribe((res: any) => {
            let portfolioIndex = this.oppoDataList.findIndex(
              (e: any) => e.id == item.parent.id
            );
            let subportfolioIndex = this.oppoDataList[
              portfolioIndex
            ].children.findIndex((e: any) => e.id == item.id);
            let rec = res.program;
            if (rec.length > 0) {
              rec.forEach((element: any) => {
                element.level = "program";
                element.type = "opportunity";
                element.parent = {
                  id: this.oppoDataList[portfolioIndex].children[
                    subportfolioIndex
                  ].id,
                  level: "sub_portfolio",
                  parent: {
                    id: this.oppoDataList[portfolioIndex].id,
                    level: "portfolio",
                  },
                };
              });
            }

            this.oppoDataList[portfolioIndex].children[
              subportfolioIndex
            ].children = rec;
            return rec;
          });
      } else if (item.level == "program") {
        let queryParam = `sub_portfolio=${item.parent.id}&program=${item.id}&goal=${this.selected}`;

        response = await this.okrConvergenceService
          .getGoalOpp(queryParam)
          .subscribe((res: any) => {
            let portfolioIndex = this.oppoDataList.findIndex(
              (e: any) => e.id == item.parent.parent.id
            );
            let subportfolioIndex = this.oppoDataList[
              portfolioIndex
            ].children.findIndex((e: any) => e.id == item.parent.id);
            let programIndex = this.oppoDataList[portfolioIndex].children[
              subportfolioIndex
            ].children.findIndex((e: any) => e.id == item.id);

            if (res.opportunity.length > 0) {
              res.opportunity.forEach((element: any) => {
                element.level = "last";
                element.type = "opportunity";
                element.parent = {
                  id: this.oppoDataList[portfolioIndex].children[
                    subportfolioIndex
                  ].children[programIndex].id,
                  level: "program",
                  parent: {
                    id: this.oppoDataList[portfolioIndex].children[
                      subportfolioIndex
                    ].id,
                    level: "sub_portfolio",
                    parent: {
                      id: this.oppoDataList[portfolioIndex].id,
                      level: "portfolio",
                    },
                  },
                };
              });
            }

            this.oppoDataList[portfolioIndex].children[
              subportfolioIndex
            ].children[programIndex].children = res.opportunity;
            return res;
          });
      }
    } else if (item.type == "project") {
      if (item.level == "portfolio") {
        let queryParam = `portfolio=${item.id}&goal=${this.selected}`;

        response = await this.okrConvergenceService
          .getGoalProject(queryParam)
          .subscribe((res: any) => {
            if (res.sub_portfolio.length > 0) {
              res.sub_portfolio.forEach((element: any) => {
                element.parent = item;
                element.type = "project";
              });
            }
            this.projectDataList[this.projectDataList.indexOf(item)].children =
              res.sub_portfolio;
            return res;
          });
      } else if (item.level == "sub_portfolio") {
        let queryParam = `sub_portfolio=${item.id}&goal=${this.selected}`;

        response = await this.okrConvergenceService
          .getGoalProject(queryParam)
          .subscribe((res: any) => {
            let portfolioIndex = this.projectDataList.findIndex(
              (e: any) => e.id == item.parent.id
            );
            let subportfolioIndex = this.projectDataList[
              portfolioIndex
            ].children.findIndex((e: any) => e.id == item.id);
            let rec = res.program;
            if (rec.length > 0) {
              rec.forEach((element: any) => {
                element.level = "program";
                element.type = "project";
                element.parent = {
                  id: this.projectDataList[portfolioIndex].children[
                    subportfolioIndex
                  ].id,
                  level: "sub_portfolio",
                  parent: {
                    id: this.projectDataList[portfolioIndex].id,
                    level: "portfolio",
                  },
                };
              });
            }

            this.projectDataList[portfolioIndex].children[
              subportfolioIndex
            ].children = rec;
            return rec;
          });
      } else if (item.level == "program") {
        let queryParam = `sub_portfolio=${item.parent.id}&program=${item.id}&goal=${this.selected}`;

        response = await this.okrConvergenceService
          .getGoalProject(queryParam)
          .subscribe((res: any) => {
            let portfolioIndex = this.projectDataList.findIndex(
              (e: any) => e.id == item.parent.parent.id
            );
            let subportfolioIndex = this.projectDataList[
              portfolioIndex
            ].children.findIndex((e: any) => e.id == item.parent.id);
            let programIndex = this.projectDataList[portfolioIndex].children[
              subportfolioIndex
            ].children.findIndex((e: any) => e.id == item.id);

            if (res.project.length > 0) {
              res.project.forEach((element: any) => {
                element.level = "last";
                element.type = "project";
                element.parent = {
                  id: this.projectDataList[portfolioIndex].children[
                    subportfolioIndex
                  ].children[programIndex].id,
                  level: "program",
                  parent: {
                    id: this.projectDataList[portfolioIndex].children[
                      subportfolioIndex
                    ].id,
                    level: "sub_portfolio",
                    parent: {
                      id: this.projectDataList[portfolioIndex].id,
                      level: "portfolio",
                    },
                  },
                };
              });
            }

            this.projectDataList[portfolioIndex].children[
              subportfolioIndex
            ].children[programIndex].children = res.project;
            return res;
          });
      }
    }
    return response;
  }

  navigateTo(item: any) {
    this.router.navigate([`view/` + btoa(item.id) + `/overview`]);
  }

  // filtersFunctions() {
  //   this.getOpportunity();
  //   this.getProjectListData();
  // }

  // getOkrHeatMap() {
  //   this.spinner.show();
  //   this.okrConvergenceService.getOkrHeatMap(this.portFolioId, this.subPortId,this.initativeId, this.projectId).subscribe((getHeatMap: any) => {

  //     if (getHeatMap?.key_result_list?.length > 0) {
  //       this.okrHeatMapData = getHeatMap.key_result_list.map((heatMapData: any) => { return { ...heatMapData, color: this.getRandomcolors() } });

  //     }else{
  //       this.okrHeatMapData=getHeatMap.key_result_list
  //     }
  //     //this.setHeatMapChart();
  //       this.getAllProjectList();
  //   }, (error) => {
  //     this.spinner.hide();
  //   })
  // }

  // setHeatMapChart() {
  //   let call = this;
  //   let chartOptions1: any = {
  //     title: {
  //       text: ''
  //     },
  //     credits: {
  //       enabled: false
  //     },
  //     series: [{
  //       type: "treemap",
  //       levels: [
  //         {
  //           level: 1,
  //           dataLabels: {
  //             enabled: true,
  //             useHTML: true,
  //             style: {
  //               // fontSize: '16px',
  //               // fontWeight: "normal",
  //               // textOutline: "0",
  //               // textOverflow: "none",
  //               //padding:'5px',
  //               //width:"40px"
  //             },
  //           }
  //         }
  //       ],
  //       events: {
  //         click(e: any) {
  //           call.showAllProjects = false;
  //           call.projectViewAll = false;
  //           call.ideasViewAll = false;
  //           call.opportunityViewAll = false;
  //           call.spinner.show();
  //           call.getProjectList(e.point.id)
  //         }
  //       },
  //       layoutAlgorithm: 'squarified',
  //       data: [...this.okrHeatMapData]
  //     }]
  //   };
  //   chartOptions1.series[0].levels[0].dataLabels.formatter = function () {
  //     return "<p class='pl-1'>" + this.point.name + "</p><p class='pl-1 point-values'><span>" + (this.point.kr_value ? this.point.kr_value : 0) + "</span> " + (this.point.kr_value > 1 ? ' Key Results' : ' Key Result') + "</p>"
  //   }
  //   this.treeMapChart1 = new Chart(chartOptions1)
  // }

  // getOpportunity() {
  //   this.ProgramService.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
  //     this.CarouselFilterList = res.records.find((findPortFolio: any) => findPortFolio.field_name === "opportunity_portfolio").field_values;
  //   }, (error: any) => {
  //     throw error;

  //   })
  // }
  // getProjectList(projectId?: any) {
  //   this.spinner.show()
  //   this.selectedProject = this.okrHeatMapData.find((findCurrentData: any) => findCurrentData.id === projectId) || this.okrHeatMapData.shift();
  //   this.okrConvergenceService.getHeatMapProjects(projectId).subscribe((getProjectsList: any) => {
  //     this.projectList = getProjectsList?.body?.project;
  //     this.ideaList = getProjectsList?.body?.idea;
  //     this.opportunityList = getProjectsList?.body?.opportunity;
  //     this.spinner.hide();
  //   }, (error) => {
  //     this.spinner.hide();
  //   })
  // }

  // getAllProjectList() {
  //   this.spinner.show();
  //   this.okrConvergenceService.getAllHeatMapProjects(this.portFolioId, this.subPortId, this.initativeId, this.projectId).subscribe((getAllProjects: any) => {
  //     this.spinner.hide();
  //     this.allOkrHeatMapProjects = getAllProjects;
  //     console.log("allOkrHeatMapProjects", this.allOkrHeatMapProjects);
  //     this.allOkrHeatMapProjects.forEach((ele: any, i: any) => {
  //       let count = 0;
  //       // let val = this.allOkrHeatMapProjects[i]['okrList']['idea'] ? this.allOkrHeatMapProjects[i]['okrList']['idea'] :[];
  //       // count +=  val?.reduce( (p:any, c:any) => p = p + c?.key_results.length, 0);
  //       // let val2 = this.allOkrHeatMapProjects[i]['okrList']['opportunity'] ? this.allOkrHeatMapProjects[i]['okrList']['opportunity'] :[];
  //       // count +=  val2?.reduce( (p:any, c:any) => p = p + c?.opportunity_key_results.length, 0);
  //       let val3 = this.allOkrHeatMapProjects[i]['okrList']['project'] ? this.allOkrHeatMapProjects[i]['okrList']['project'] : [];
  //       val3.forEach((project: any, prindex: any) => {
  //         count +=  val3[prindex]['project_key_results'].length || 0
  //         // projectkr.forEach((kr:any,krind:any)=>{
  //         //   let krval = projectkr[krind]?.goal?.key_results;
  //         //   count += krval?.reduce((p: any, c: any) => p = p + c?.child_key_results?.length, 0);
  //         // })
  //       })
  //       let foundindex = this.okrHeatMapData.findIndex((e: any) => e.name == ele.name)
  //       if (foundindex > -1)
  //         this.okrHeatMapData[foundindex]['kr_value'] = count
  //     })
  //     this.setHeatMapChart();
  //   }, (error: any) => {
  //     this.spinner.hide();
  //   })
  // }

  // getRandomcolors() {
  //   const colors: any[] = ["#FFA800", "#1A85D6", "#07B797", "#738199", "#B58848", "#706FD3", "#F3819A", "#00F000", "#52E6CB", "#232459", "#EB55D7", "#738199", "#B58848", "#706FD3", "#F3821A", "#00F210", "#72E8CB", "#235359", "#EB32D7"];
  //   let colorIndex = Math.floor(Math.random() * colors.length);
  //   return colors[colorIndex];
  // }

  // selectedPortfolioValues(id: any) {
  //   this.portFolioId = id;
  //   this.subPortId = "";
  //   let selectedPortfolio = this.CarouselFilterList.find((e:any)=> e.id === id);
  //   this.subPortfilteredOptions = selectedPortfolio?.sub_portfolio;
  //   if (this.portFolioId==="") {
  //     this.subPortfilteredOptions = [];
  //   }
  //   this.getOkrHeatMap();
  // }

  // selectedSubportfolioValues(id: any) {
  //   this.subPortId = id;
  //   this.getOkrHeatMap();
  // }
  // clearFilter() {
  //   this.portFolioId = 0;
  //   this.subPortId = 0;
  //   this.initativeId = 0;
  //   this.projectId = 0;
  //   this.getOkrHeatMap();
  // }

  // getProjectListData() {
  //   this.okrConvergenceService.projectList().subscribe((projectList: any) => {
  //     if (projectList) {
  //       this.projectListData = projectList;
  //     }
  //   })
  // }

  // selectedProjectId(id: number) {
  //   this.projectId = id;
  //   this.getOkrHeatMap();
  // }
  // toggleAccordian(index: any) {
  //   let val = this.allProjectsection[index];
  //   this.allProjectsection = [];
  //   this.allProjectsection[index] = !val;
  // }
  // toggleProjectAccordian(index: any) {
  //   let val = this.allProjectArrow[index];
  //   this.allProjectArrow = [];
  //   this.allProjectArrow[index] = !val;
  // }
  // toggleIdeaProjectAccordian(index: any) {
  //   let val = this.allIdeadArrow[index];
  //   this.allIdeadArrow = [];
  //   this.allIdeadArrow[index] = !val;
  // }
  // toggleOppAccordian(index: any) {
  //   let val = this.allOpportunityArrow[index];
  //   this.allOpportunityArrow = [];
  //   this.allOpportunityArrow[index] = !val;
  // }
  // navigateTo(id:any){
  //   this.router.navigate(['/view/'+btoa(id)+'/okrlog'])
  // }
  // navigateToPreview(id:any){
  //   this.router.navigate(['/rfx/'+btoa(id)+'/opportunity/preview'])
  // }
  // navigateToInnovation(id:any){
  //   this.router.navigate(['/innovation/detail-ideas/'+btoa(id)])
  // }
  // navigateToOKRlog(id:any){
  //   this.router.navigate(['/view/'+btoa(id)+'/okrlog'])
  // }

  openOpportunity(res: any) {
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: false,
      width: "80vw",
      data: {
        opportunity: res.id,
      },
    });
    dialog.componentInstance.type = { name: "opportunitypop" };
    dialog.afterClosed().subscribe((data: any) => {
      if (!!data) {
        window.location.href = data.replace("embedded=true", "");
      }
    });
  }
  openProjects(res: any) {
    let newitem = {
      project_id: res.id,
      program_name: res.programs[0].name,
      program_id: res.programs[0].id,
      subportfolio_name: res.sub_portfolios[0].name,
      subportfolio_id: res.sub_portfolios[0].id,
      portfolio_name: res.portfolios[0].name,
      portfolio_id: res.portfolios[0].id,
    };
    localStorage.setItem("program", JSON.stringify(newitem));

    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, {
      hasBackdrop: true,
      disableClose: false,
      width: "80vw",
      data: {
        opportunity: res.id,
      },
    });
    dialog.componentInstance.type = { name: "projectpop" };
    dialog.afterClosed().subscribe((data: any) => {
      if (!!data) {
        window.location.href = data.replace("embedded=true", "");
      }
    });
  }
}
